import React, { useState } from "react";

import {
  Box,
  Button,
  Input,
  Table,
  ButtonIcon,
  Modal,
} from "../../../components";

import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { faPlus, faRedo } from "@fortawesome/free-solid-svg-icons";
import * as utils from "../../../components/styled-utils";
import CreateVesselViewBox from "./CreateVesselViewBox";
const VesselViews = ({
  vesselViews,
  selectView,
  updateView,
  deleteView,
  createVesselView,
  showCreatePanel,
  vesselId,
  getVessels,
}) => {
  const [visitsInput, setVisitsInput] = useState(null);
  const [areaInput, setAreaInput] = useState(null);
  const [snapshotsInput, setSnapshotsInput] = useState(null);
  const [notesInput, setNotesInput] = useState(null);
  const [matterportInput, setMatterposrtInput] = useState(null);
  const [descInput, setdesctInput] = useState(null);
  const [nametInput, setnameInput] = useState(null);
  const [edit, setEdit] = useState(false);
  const [editRowId, setEditRowId] = useState();

  const saveVesselView = (visits) => {
    console.log(visits);
    setEdit(false);
    setEditRowId(null);
  };
  const onEditButtonClick = (
    id,
    matterportId,
    title,
    description,
    visits,

    area,
    snapshots,
    notes,
    isEdit
  ) => {
    if (isEdit) {
      const sendingData = {};
      if (visitsInput || visitsInput === 0) {
        sendingData.visits = visitsInput;
      }
      if (areaInput || areaInput === 0) {
        sendingData.area = areaInput;
      }
      if (snapshotsInput || snapshotsInput === 0) {
        sendingData.snapshots = snapshotsInput;
      }
      if (notesInput || notesInput === 0) {
        sendingData.notes = notesInput;
      }
      if (matterportInput || matterportInput === "") {
        sendingData.matterportId = matterportInput;
      }
      if (descInput || descInput === "") {
        sendingData.description = descInput;
      }
      if (nametInput || nametInput === "") {
        sendingData.title = nametInput;
      }

      updateView(sendingData, id);
      vesselViews = vesselViews.map((view) => {
        if (view.id === id) {
          view.visits = visitsInput;
          view.area = Number(areaInput);
          view.snapshots = Number(snapshotsInput);
          view.notes = Number(notesInput);
          view.matterportId = matterportInput;
          view.title = nametInput;
          view.description = descInput;
        }
        return view;
      });
      setEdit(false);
      setEditRowId(null);
    } else {
      setVisitsInput(visits);
      setAreaInput(area);
      setSnapshotsInput(snapshots);
      setNotesInput(notes);
      setMatterposrtInput(matterportId);
      setnameInput(title);
      setdesctInput(description);
      setEdit(true);
      setEditRowId(id);
    }
  };

  const onAreaChange = (ev, id, area) => {
    if (id === editRowId) {
      setAreaInput(ev.target.value);
    } else {
      setAreaInput(area);
    }
  };

  const onVisitsChange = (ev, id, visits) => {
    if (id === editRowId) {
      setVisitsInput(ev.target.value);
    } else {
      setVisitsInput(visits);
    }
  };

  const onSnapshotsChange = (ev, id, snapshots) => {
    if (id === editRowId) {
      setSnapshotsInput(ev.target.value);
    } else {
      setSnapshotsInput(snapshots);
    }
  };

  const onNotesChange = (ev, id, notes) => {
    if (id === editRowId) {
      setNotesInput(ev.target.value);
    } else {
      setNotesInput(notes);
    }
  };

  // handle changes for rest values
  const onDeskriptionChange = (ev, id, description) => {
    if (id === editRowId) {
      setdesctInput(ev.target.value);
    } else {
      setdesctInput(description);
    }
  };

  const onMatterportIdChange = (ev, id, matterportId) => {
    if (id === editRowId) {
      setMatterposrtInput(ev.target.value);
    } else {
      setMatterposrtInput(matterportId);
    }
  };

  const onTitleChange = (ev, id, title) => {
    if (id === editRowId) {
      setnameInput(ev.target.value);
    } else {
      setnameInput(title);
    }
  };

  //end

  return (
    <Box direction="col" width="100%" alignItems="flex-start">
      <Box
        width="100%"
        justify="between"
        alignItems="flex-start"
        marginBottom={1}
      >
        <Box>
          <ButtonIcon onClick={createVesselView}>
            <FA icon={faPlus} color={utils.colors.smoke} size="xs" />
          </ButtonIcon>
          <ButtonIcon onClick={getVessels}>
            <FA icon={faRedo} color={utils.colors.smoke} size="xs" />
          </ButtonIcon>
        </Box>
      </Box>

      <Table slim>
        <Table.Head>
          <Table.Row>
            {[
              "ID",
              "Title",
              "Description",
              "Matterport Id",
              "Visits",
              "Area",
              "Scan",
              "Note",
              "Action",
            ].map((item) => (
              <Table.Header key={item}>{item}</Table.Header>
            ))}
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {vesselViews.map((view) => {
            const {
              id,
              title,
              description,
              matterportId,
              visits,
              area,
              notes,
              snapshots,
            } = view;
            return (
              <Table.Row key={id}>
                <Table.Cell>{id}</Table.Cell>
                <Table.Cell>
                  {
                    <div className="visit-input">
                      {edit && editRowId === id ? (
                        <Input
                          name="title"
                          type="string"
                          value={nametInput !== null ? nametInput : title}
                          onChange={(ev) => {
                            onTitleChange(ev, id, title);
                          }}
                        />
                      ) : (
                        title
                      )}
                    </div>
                  }
                </Table.Cell>
                <Table.Cell>
                  <div className="visit-input">
                    {edit && editRowId === id ? (
                      <Input
                        name="description"
                        type="string"
                        value={descInput !== null ? descInput : description}
                        onChange={(ev) => {
                          onDeskriptionChange(ev, id, description);
                        }}
                      />
                    ) : (
                      description
                    )}
                  </div>
                </Table.Cell>
                <Table.Cell>
                  {
                    <div className="visit-input">
                      {edit && editRowId === id ? (
                        <Input
                          name="matterportId"
                          type="string"
                          value={
                            matterportInput !== null
                              ? matterportInput
                              : matterportId
                          }
                          onChange={(ev) => {
                            onMatterportIdChange(ev, id, matterportId);
                          }}
                        />
                      ) : (
                        matterportId
                      )}
                    </div>
                  }
                </Table.Cell>
                <Table.Cell>
                  <div className="visit-input">
                    {edit && editRowId === id ? (
                      <Input
                        name="visits"
                        type="number"
                        value={visitsInput !== null ? visitsInput : visits}
                        onChange={(ev) => {
                          onVisitsChange(ev, id, visits);
                        }}
                      />
                    ) : (
                      visits
                    )}
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <div className="area-input">
                    {edit && editRowId === id ? (
                      <Input
                        name="area"
                        type="number"
                        value={areaInput !== null ? areaInput : area}
                        onChange={(ev) => {
                          onAreaChange(ev, id, area);
                        }}
                      />
                    ) : (
                      <>
                        {area ? area.toFixed(1) : null}&nbsp;m<sup>2</sup>
                      </>
                    )}
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <div className="visit-input">
                    {edit && editRowId === id ? (
                      <Input
                        name="snapshots"
                        type="number"
                        value={
                          snapshotsInput !== null
                            ? snapshotsInput
                            : snapshots || 0
                        }
                        onChange={(ev) => {
                          onSnapshotsChange(ev, id, snapshots);
                        }}
                      />
                    ) : (
                      snapshots || 0
                    )}
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <div className="visit-input">
                    {edit && editRowId === id ? (
                      <Input
                        name="notes"
                        type="number"
                        value={notesInput !== null ? notesInput : notes || 0}
                        onChange={(ev) => {
                          onNotesChange(ev, id, notes);
                        }}
                      />
                    ) : (
                      notes || 0
                    )}
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <div style={{ display: "flex" }}>
                    <Button
                      onClick={() => {
                        onEditButtonClick(
                          id,
                          matterportId,
                          title,
                          description,
                          visits,
                          area,
                          snapshots,
                          notes,
                          edit && editRowId === id
                        );
                      }}
                      style={{ marginRight: 8 }}
                      type="submit"
                    >
                      {edit && editRowId === id ? "Save" : "Edit"}
                    </Button>
                    <Button type="submit" danger onClick={() => deleteView(id)}>
                      Delete
                    </Button>
                  </div>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      {showCreatePanel && (
        <Modal alignItems="unset" isOpen={showCreatePanel}>
          <CreateVesselViewBox vesselId={vesselId} />
        </Modal>
      )}
    </Box>
  );
};

export default VesselViews;
