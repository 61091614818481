import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import "animate.css/animate.min.css";
import 'react-phone-number-input/style.css'

import config from "./global/store";

import { PrivateRoute } from "./hoc";

import Verify from "./routes/login/Verify";
import Login from "./routes/login/Login";

import Dashboard from "./routes/Dashboard";
import NotFound from "./routes/notfound/NotFound";

class App extends Component {
  render() {
    return (
      <>
        <ReactNotification />
        <Provider store={config.store}>
          <PersistGate persistor={config.persistor} loading={null}>
            <div>
              <Router>
                <Switch>
                  <PrivateRoute  path="/verify" component={Verify}/>
                  <PrivateRoute  path="/dashboard" component={Dashboard} />
                  <Route path="/" component={Login} />
                  <Route path="/login" component={Login} />
                  <Route component={NotFound} />
                </Switch>
              </Router>
            </div>
          </PersistGate>
        </Provider>
      </>
    );
  }
}

export default App;
