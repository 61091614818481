import React, { PureComponent } from "react";
import { connect } from "react-redux";

import dayjs from "dayjs";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { faPlus, faRedo } from "@fortawesome/free-solid-svg-icons";

import { Box, Input, Table, ButtonIcon } from "../../components";
import * as utils from "../../components/styled-utils";

import { getAllAccounts } from "../../entities/accounts/reducer";
import { entitySearch } from "../../entities";

import CreateAccount from "./CreateAccount";
import UpdateAccount from "./UpdateAccount";

class Accounts extends PureComponent {
  static defaultProps = {
    accounts: [],
    isFetching: false
  };

  state = {};

  handleSearch = event => {
    event.preventDefault();
    const { search } = this.props;
    search(event.target.value);
  };

  refresh = () => {
    const { refresh, reset } = this.props;
    reset();
    refresh();
  };

  onCreate = () => {
    const { createAccount } = this.props;
    createAccount();
  };

  selectRow = (event, accountId) => {
    const { updateAccount } = this.props;
    this.setState({ selectedAccount: accountId });
    updateAccount(accountId);
  };

  componentWillUnmount() {
    const { reset, clearSearch } = this.props;
    clearSearch();
    reset();
  }

  render() {
    const {
      accounts,
      isFetching,
      showCreatePanel,
      showUpdatePanel
    } = this.props;
    const { selectedAccount } = this.state;
    return (
      <Box direction="col" width="100%" alignItems="flex-end">
        <Box
          width="100%"
          justify="between"
          alignItems="flex-start"
          marginBottom={1}
        >
          <Box>
            <ButtonIcon onClick={this.onCreate}>
              <FA icon={faPlus} color={utils.colors.smoke} size="xs" />
            </ButtonIcon>
            <ButtonIcon onClick={this.refresh}>
              <FA icon={faRedo} color={utils.colors.smoke} size="xs" />
            </ButtonIcon>
          </Box>
          <Box basis="320px">
            <Input
              type="text"
              name="search"
              onChange={this.handleSearch}
              placeholder="Search accounts..."
            />
          </Box>
        </Box>

        <Table loading={isFetching}>
          <Table.Head>
            <Table.Row>
              {[
                "ID",
                "Account name",
                "Description",
                "Date created",
                "Valid until",
                "Active"
              ].map(item => (
                <Table.Header key={item}>{item}</Table.Header>
              ))}
            </Table.Row>
          </Table.Head>

          <Table.Body>
            {accounts.map(account => {
              const {
                id,
                name,
                description,
                isActive,
                validUntil,
                createdAt
              } = account;
              return (
                <Table.Row
                  key={id}
                  onClick={event => this.selectRow(event, id)}
                >
                  <Table.Cell>{id}</Table.Cell>
                  <Table.Cell>{name}</Table.Cell>
                  <Table.Cell>{description && description}</Table.Cell>
                  <Table.Cell>
                    {createdAt ? dayjs(createdAt).format("DD MMM YYYY") : null}
                  </Table.Cell>
                  <Table.Cell>
                    {validUntil
                      ? dayjs(validUntil).format("DD MMM YYYY")
                      : null}
                  </Table.Cell>
                  <Table.Cell>{isActive}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        {showCreatePanel && <CreateAccount />}
        {showUpdatePanel && (
          <UpdateAccount accountId={selectedAccount} key={selectedAccount} />
        )}
      </Box>
    );
  }
}

const mapStateToProps = state => {
  const accounts = entitySearch(state)(
    getAllAccounts,
    state => state.accounts.search,
    ["name", "description"]
  );

  return {
    accounts: accounts(state),
    isFetching: state.accounts.isFetching.allAccounts,
    showCreatePanel: state.ui.accounts.createAccount,
    showUpdatePanel: state.ui.accounts.updateAccount
  };
};

const mapDispatchToProps = dispatch => ({
  refresh: () => dispatch({ type: "ACCOUNTS_REQUEST" }),
  search: value => dispatch({ type: "ACCOUNTS_SEARCH", payload: { value } }),
  clearSearch: () => dispatch({ type: "RESET_ACCOUNTS_SEARCH" }),
  createAccount: () => dispatch({ type: "CREATE_ACCOUNT" }),
  updateAccount: accountId =>
    dispatch({ type: "UPDATE_ACCOUNT", payload: { accountId: accountId } }),
  reset: () => dispatch({ type: "RESET_ACCOUNT_FLOWS" })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Accounts);
