import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import animejs from "animejs";
import PhoneInput from 'react-phone-number-input'
import { Box, Input, Button } from "../../components";
import { isLoading, getToken } from "../../entities/auth/reducer";
import {sendVerification, verify} from "../../api/verification";
import {store} from "react-notifications-component";

const Verify = ({token, history, getCurrentUser}) => {
  const [phoneNumber, setPhoneNumber] = useState();
  const [code, setCode] = useState();
  const [isSend, setIsSend] = useState(false)

  useEffect(() => {
    //const verified = true
    const verified = localStorage.getItem('verified') === 'verified';
    getCurrentUser();

    if(verified) {
      history.push('/dashboard/users')
    }
  },  [])

  const onChange = event => {
    event.preventDefault();
  };


const onVerifyButtonClick = event => {
   event.preventDefault();
   localStorage.setItem('verified', 'verified');
   store.addNotification({
      title: "Success",
      message: "Phone Verified",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeInRight"],
      animationOut: ["animate__animated", "animate__fadeOutRight"],
      dismiss: {
            duration: 2000,
            delay: 1000,
            showIcon: true,
      },
      slidingEnter: {
            duration: 1000,
      },
   });
   const timeout = setTimeout(() => {
      history.push('/dashboard/users')
      clearTimeout(timeout);
   }, 2000);
};

const onSendButtonClick = event => {
   event.preventDefault();
   setIsSend(true);
   store.addNotification({
      title: "Success",
      message: "Skipping code",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeInRight"],
      animationOut: ["animate__animated", "animate__fadeOutRight"],
      dismiss: {
         duration: 5000,
         delay: 1000,
         showIcon: true,
      },
      slidingEnter: {
         duration: 2000,
      },
   });
}

/*
  const onVerifyButtonClick = event => {
    event.preventDefault();
    verify(phoneNumber, code, token).then(res => {
      if( res.status === 200) {
        localStorage.setItem('verified', 'verified');
        store.addNotification({
          title: "Success",
          message: res.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeInRight"],
          animationOut: ["animate__animated", "animate__fadeOutRight"],
          dismiss: {
            duration: 2000,
            delay: 1000,
            showIcon: true,
          },
          slidingEnter: {
            duration: 1000,
          },
        });
        const timeout = setTimeout(() => {
          history.push('/dashboard/users')
          clearTimeout(timeout);
        }, 2000);
      }
    })
  };

  const onSendButtonClick = event => {
    sendVerification(phoneNumber, token).then(res => {
      if( res.status === 200) {
        setIsSend(true);
        store.addNotification({
          title: "Success",
          message: res.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeInRight"],
          animationOut: ["animate__animated", "animate__fadeOutRight"],
          dismiss: {
            duration: 5000,
            delay: 1000,
            showIcon: true,
          },
          slidingEnter: {
            duration: 2000,
          },
        });
      }
    }).catch(err => {
      store.addNotification({
        title: "Error",
        message: "Cannot send to your number.",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeInRight"],
        animationOut: ["animate__animated", "animate__fadeOutRight"],
        dismiss: {
          duration: 5000,
          delay: 1000,
          showIcon: true,
        },
        slidingEnter: {
          duration: 2000,
        },
      });
    })
  };
*/
  return (
    <Box
      background="rgb(28,28,29)"
      imageUrl="https://images.unsplash.com/photo-1449028288989-df05d40bcbb4?ixlib=rb-1.2.1&auto=format&fit=crop&w=1952&q=80"
      width="100vw"
      height="100vh"
      justify="center"
      flexDirection="col"
    >
      <Box
        style={{ transform: "translateX(0)" }}
        gradient={`linear-gradient(
          135deg,
          rgba(48,48,49,0.75),
          rgba(28,28,29,0.75)
        )`}
        shape="rounded"
        flexDirection="col"
        paddingVertical={3}
        paddingHorizontal={2}
        elevate
        marginBottom={2}
      >
        <Box  flexDirection="col" marginBottom={2}>
          <PhoneInput
            name='phone'
            withCountryCallingCode
            placeholder="Enter phone number"
            value={phoneNumber}
            onChange={setPhoneNumber}/>
        </Box>
        <Box justify="center" width="100%" marginBottom={2}>
          <Button onClick={onSendButtonClick} fullWidth>
            { isSend ? 'SEND CODE AGAIN' : 'SEND CODE' }
          </Button>
        </Box>
        {isSend && (
          <>
            <Box width="100%" flexDirection="col">
              <Input
                name='code'
                placeholder="Enter code"
                value={code}
                onChange={(e) => { setCode(e.target.value)}}
              />
            </Box>
            <Box justify="center" width="100%">
              <Button onClick={onVerifyButtonClick} fullWidth>
                VERIFY
              </Button>
            </Box>
          </>
        )}
      </Box>
      <span style={{ fontSize: 11, color: "rgba(205,205,205,1)" }}>
        powered by Vesselfront™
      </span>
    </Box>
  );
}

const mapStateToProps = state => ({
  token: getToken(state)
});

const mapDispatchToProps = dispatch => ({
  getCurrentUser: () => dispatch({ type: "CURRENT_USER_REQUEST" })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Verify);
