import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";

import { Box, Avatar, Text, Divider, LinkBox } from "../components";
import { colors } from "../components/styled-utils";
import Notifications from "./Notifications";

import Users from "./users";
import Accounts from "./accounts";
import Vessels from "./vessels";

import { getErrors } from "../entities/errors/reducer";
import { getCurrentUser } from "../entities/users/reducer";

const Links = [
  { name: "Accounts", path: "/dashboard/accounts" },
  { name: "Users", path: "/dashboard/users" },
  { name: "Vessels", path: "/dashboard/vessels" },
];

const Sidebar = ({ history, signOut, fullname, avatar, usergroup }) => {
  const logout = () => {
    signOut();
  };
  return (
    <Box
      minHeight="100vh"
      gradient="linear-gradient(135deg, rgb(48,48,49), rgb(28,28,29))"
      background={colors.darkGray}
      basis="12.5%"
      justify="center"
      alignItems="center"
      direction="col"
      elevate
    >
      <Box
        width="100%"
        flex="shrink"
        justify="center"
        direction="col"
        paddingVertical={3}
      >
        <Avatar
          height={80}
          width={80}
          circle
          avatar={avatar}
          marginBottom={2}
        />
        <Text weight="500" marginBottom={1}>
          {fullname}
        </Text>
        <Text weight="400" size="12px">
          {usergroup}
        </Text>
      </Box>
      <Box
        width="100%"
        flex="grow"
        direction="col"
        paddingHorizontal={2}
        paddingVertical={3}
      >
        {Links.map((link) => (
          <LinkBox key={link.name} path={link.path} history={history}>
            <Text
              width="100%"
              paddingLeft={1}
              marginBottom={1}
              size="14px"
              weight={500}
            >
              {link.name}
            </Text>
            <Divider color="lightGray" marginBottom={2} />
          </LinkBox>
        ))}
      </Box>
      <Box width="100%" flex="shrink" justify="center" direction="col">
        <Divider color="lightGray" marginBottom={2} />
        <Text
          onClick={logout}
          weight={500}
          marginBottom={2}
          style={{ cursor: "pointer" }}
        >
          Sign out
        </Text>
      </Box>
    </Box>
  );
};

class Dashboard extends React.PureComponent {
  componentDidMount() {
    const { getUsers, getCurrentUser, getAccounts, getVessels, signOut } =
      this.props;
    const verified = localStorage.getItem("verified") === "verified";

    if (!verified) {
      signOut();
      return;
    }
    getCurrentUser();
    getUsers();
    getAccounts();
    getVessels();
  }

  render() {
    const { fullname, avatar, usergroup } = this.props;
    return (
      <Box
        minWidth="100vw"
        minHeight="100vh"
        background={colors.black}
        alignItems="start"
      >
        <Sidebar
          history={this.props.history}
          signOut={this.props.signOut}
          fullname={fullname}
          avatar={avatar}
          usergroup={usergroup}
        />
        <Box
          paddingVertical={4}
          paddingHorizontal={4}
          basis="87.5%"
          height="100vh"
          alignItems="flex-start"
          justify="between"
          overflow="auto"
        >
          <Switch>
            <Route path="/dashboard/accounts" component={Accounts} />
            <Route path="/dashboard/users" component={Users} />
            <Route path="/dashboard/vessels" component={Vessels} />
          </Switch>
          <Notifications />
        </Box>
      </Box>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUsers: () => dispatch({ type: "USERS_REQUEST" }),
  getCurrentUser: () => dispatch({ type: "CURRENT_USER_REQUEST" }),
  getAccounts: () => dispatch({ type: "ACCOUNTS_REQUEST" }),
  getVessels: () => dispatch({ type: "VESSELS_REQUEST" }),
  signOut: () => dispatch({ type: "SIGN_OUT" }),
});

const mapStateToProps = (state) => {
  const errors = getErrors(state);
  const currentUser = getCurrentUser(state);

  return {
    fullname: currentUser && currentUser.fullname,
    avatar: currentUser && currentUser.avatar,
    usergroup: currentUser && currentUser.userGroup,
    errors,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
