import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";
import { all, fork } from "redux-saga/effects";
import matchSorter from "match-sorter";
import { createSelector } from "reselect";

// Reducers
import auth from "./auth/reducer";
import users from "./users/reducer";
import errors from "./errors/reducer";
import accounts from "./accounts/reducer";
import notifications from "./notifications/reducer";
import vessels from "./vessels/reducers";
import vesselTypes from "./vesselTypes/reducer";
import views from "./views/reducers";
import notes from "./notes/reducers";
import ui from "./ui/reducers";

// Sagas
import userSagas from "./users/sagas";

// Epics
import accountEpics from "./accounts/epics";
import authEpics from "./auth/epics";
import notificationEpics from "./notifications/epics";
import vesselEpics from "./vessels/epics";
import vesselTypesEpics from "./vesselTypes/epics";
import noteEpics from "./notes/epics";
import countriesEpic from "./countries/epics";

function* appSaga() {
  yield all([fork(userSagas)]);
}

const appReducer = combineReducers({
  auth,
  accounts,
  users,
  vessels,
  vesselTypes,
  views,
  notes,
  errors,
  notifications,
  ui,
});

export const rootReducer = (state, action) => {
  switch (action.type) {
    case "SIGN_OUT":
      localStorage.clear();
      return appReducer({}, action);
    default:
      return appReducer(state, action);
  }
};

export const rootSaga = appSaga;

export const rootEpic = combineEpics(
  authEpics,
  accountEpics,
  vesselEpics,
  vesselTypesEpics,
  notificationEpics,
  noteEpics,
  countriesEpic
);

export const entitySearch = () => (selector, keyword, keys) => {
  return createSelector([selector, keyword], (entities, filter) => {
    return filter
      ? matchSorter(entities, filter, {
          keys: [...keys],
        })
      : entities;
  });
};
