import React, { useState } from "react";
import DropToUpload from "react-drop-to-upload";
import { Formik } from "formik";

import {
  Box,
  Input,
  Button,
  Divider,
  H4,
  Dropdown,
  Avatar,
} from "../../../components";
import { colors } from "../../../components/styled-utils";

const CreateVessel = ({
  createVessel,
  accountList,
  vesselTypesList,
  flagsList,
}) => {
  const [vesselPic, setVesselPic] = useState();
  const numberReg = new RegExp("^[0-9]*$");
  const validateForm = (values) => {
    let errors = {};
    if (!values.imo) {
      errors.imo = "Required";
    } else if (!numberReg.test(values.imo)) {
      errors.imo = "Imo must be a number";
    }
    if (!values.mmsi) {
      errors.mmsi = "Required";
    } else if (!numberReg.test(values.mmsi)) {
      errors.mmsi = "Mmsi must be a number";
    }
    if (!values.name) {
      errors.name = "Required";
    }
    if (!values.length) {
      errors.length = "Required";
    } else if (!numberReg.test(values.length)) {
      errors.length = "Length must be a number";
    }
    if (!values.width) {
      errors.width = "Required";
    } else if (!numberReg.test(values.width)) {
      errors.width = "Width must be a number";
    }
    if (!values.tonage) {
      errors.tonage = "Required";
    } else if (!numberReg.test(values.tonage)) {
      errors.tonage = "Tonage must be a number";
    }
    if (!values.dwt) {
      errors.dwt = "Required";
    } else if (!numberReg.test(values.dwt)) {
      errors.dwt = "Dwt must be a number";
    }
    if (!values.year) {
      errors.year = "Required";
    } else if (!numberReg.test(values.year)) {
      errors.year = "Year must be a number";
    }
    return errors;
  };
  return (
    <Box
      background={colors.darkGray}
      gradient={`linear-gradient(135deg, rgba(48,48,49,1), rgba(28,28,29,1))`}
      width="100%"
      shape="rounded"
      elevate
      direction="col"
      alignItems="start"
      paddingVertical={2}
      paddingHorizontal={2}
    >
      <H4 marginBottom={2} light>
        Create Vessel
      </H4>
      <Divider marginBottom={2} color="lightGray" />
      <Formik
        validate={validateForm}
        onSubmit={(values) => {
          createVessel({ values });
        }}
        render={({
          handleChange,
          handleSubmit,
          setFieldValue,
          handleBlur,
          errors,
          touched,
          isValid,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box marginBottom={2}>
              <DropToUpload
                onDropDataURI={(dataURIs, files) => {
                  setFieldValue("image", files[0]);
                  setVesselPic(dataURIs[0]);
                }}
              >
                <Avatar circle big avatar={vesselPic || ""} />
              </DropToUpload>
            </Box>
            <Box justify="between" alignItems="start">
              <Box basis="33%" direction="col">
                <Input
                  name="imo"
                  label="IMO"
                  placeholder="Enter imo..."
                  onChange={handleChange}
                  error={touched.imo && errors.imo}
                  onBlur={handleBlur}
                />
                <Input
                  name="mmsi"
                  label="MMSI"
                  placeholder="Enter mmsi..."
                  onChange={handleChange}
                  error={touched.mmsi && errors.mmsi}
                  onBlur={handleBlur}
                />
                <Input
                  name="name"
                  label="Vessel name"
                  placeholder="Enter name..."
                  onChange={handleChange}
                  error={touched.name && errors.name}
                  onBlur={handleBlur}
                />
                <Input
                  name="length"
                  label="Vessel's length"
                  placeholder="Enter vessel's length..."
                  onChange={handleChange}
                  error={touched.length && errors.length}
                  onBlur={handleBlur}
                />
              </Box>
              <Box basis="33%" direction="col">
                <Input
                  name="width"
                  label="Vessel's width"
                  placeholder="Enter vessel's width..."
                  onChange={handleChange}
                  error={touched.width && errors.width}
                  onBlur={handleBlur}
                />
                <Input
                  name="tonage"
                  label="Vessel's tonage"
                  placeholder="Enter vessel's tonage..."
                  onChange={handleChange}
                  error={touched.tonage && errors.tonage}
                  onBlur={handleBlur}
                />
                <Input
                  name="dwt"
                  label="Vessel's DWT"
                  placeholder="Enter vessel's dwt..."
                  onChange={handleChange}
                  error={touched.dwt && errors.dwt}
                  onBlur={handleBlur}
                />
                <Input
                  name="year"
                  label="Vessel's built year"
                  placeholder="Enter vessel's built year..."
                  onChange={handleChange}
                  error={touched.year && errors.year}
                  onBlur={handleBlur}
                />
              </Box>
              <Box direction="col">
                <Dropdown
                  label="Account list"
                  name="accountId"
                  options={accountList}
                  onChange={(field, value) => {
                    setFieldValue(field, value.id);
                  }}
                  style={{ marginBottom: "16px !important" }}
                  placeholder="Select Model..."
                />
                <Dropdown
                  label="Vessel Types list"
                  name="typeId"
                  options={vesselTypesList}
                  onChange={(field, value) => {
                    setFieldValue(field, value.id);
                  }}
                  placeholder="Select Model..."
                />
                <Dropdown
                  label="Vessel Flags list"
                  name="flagId"
                  options={flagsList}
                  onChange={(field, value) => {
                    setFieldValue(field, value.id);
                  }}
                  placeholder="Select Model..."
                />
              </Box>
            </Box>
            <Box justify="end">
              <Button type="submit" disabled={!isValid}>
                Create vessel
              </Button>
            </Box>
          </form>
        )}
      />
    </Box>
  );
};

export default CreateVessel;
