import { combineReducers } from "redux";

const byId = (state = {}, action) => {
  switch (action.type) {
    case "VESSELS_SUCCESS":
    case "CREATE_VESSEL_SUCCESS":
      return {
        ...state,
        ...action.payload.entities.views,
      };

    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case "VESSELS_SUCCESS":
      const result = Object.keys(action.payload.entities.views).map((el) =>
        Number(el)
      );
      const merged = [...result];
      return [...new Set(merged)];
    case "CREATE_VESSEL_VIEW_SUCCESS":
      const merged2 = [...state, action.payload.result];
      console.log(action.payload.result, "state creation");
      return [...new Set(merged2)];
    case "VESSELS_REQUEST":
      return {
        ...state,
        allVessels: true,
      };
    case "VESSEL_VIEW_DELETE_SUCCESS":
      return [...state.filter((id) => id !== action.payload)];
    default:
      return state;
  }
};

const ui = (state = {}, action) => {
  switch (action.type) {
    case "SELECT_VIEW":
      return {
        ...state,
        editView: {
          isOpen: true,
          id: action.payload,
        },
      };
    case "RESET_VIEW_FLOWS":
    case "VESSELS_SEARCH":
      return {
        ...state,
        editView: {
          isOpen: false,
          id: null,
        },
      };

    default:
      return state;
  }
};

const views = combineReducers({
  byId,
  allIds,
  ui,
});

export const getViewById = (state, viewId) => state.views.byId[viewId];

export default views;
