import axios from "axios";
import { all, put, call, select, fork, take } from "redux-saga/effects";
import { normalize, schema } from "normalizr";

import { URL } from "../../global/constants";

import * as userActions from "./actions";
import * as notificationActions from "../notifications/actions";

import { getToken } from "../auth/reducer";

// Normalize entities for better state management
const country = new schema.Entity("country");
const avatar = new schema.Entity("avatar");
const userGroup = new schema.Entity("usergroups");

const user = new schema.Entity("users", {
  avatar: avatar,
  country: country,
  userGroup: userGroup,
});

// Async operations
const fetchUsers = (token) => {
  return axios.get(`${URL}/secured/users?admin=1`, {
    headers: {
      Authorization: token,
    },
  });
};

const fetchCurrentUser = (token) => {
  return axios.get(`${URL}/secured/users/me`, {
    headers: {
      Authorization: token,
    },
  });
};

const updateUserCall = (token, userId, values) => {
  let formData = new FormData();

  for (let i = 0; i < Object.keys(values).length; i++) {
    const field = Object.keys(values)[i];
    formData.set(field, values[field]);
  }

  return axios(`${URL}/secured/users/${userId}`, {
    method: "put",
    headers: {
      Authorization: token,
    },
    data: formData,
  });
};

const createUserApi = (token, values) => {
  return axios(`${URL}/secured/users`, {
    method: "post",
    headers: {
      Authorization: token,
    },
    data: {
      ...values,
    },
  });
};

const deleteUserApi = (token, userId) => {
  return axios(`${URL}/secured/users/${userId}`, {
    method: "delete",
    headers: {
      Authorization: token,
    },
  });
};

const permissionsApi = (token, userId) => {
  return axios.get(URL + "/secured/permissions/" + userId, {
    headers: {
      Authorization: token,
    },
  });
};

// Saga flows

function* getUsers() {
  try {
    const token = yield select(getToken);
    const response = yield call(fetchUsers, token);
    yield put(userActions.receiveUsers(normalize(response.data, [user])));
  } catch (e) {
    yield put(userActions.errorUsers(e.response.data.errors));
  }
}

function* getCurrentUser() {
  try {
    const token = yield select(getToken);
    const response = yield call(fetchCurrentUser, token);
    yield put(userActions.successCurrentUser(normalize(response.data, user)));
  } catch (e) {
    yield put({ type: "SIGN_OUT" });
    yield put(userActions.errorCurrentUser(e.response.data.errors));
  }
}

function* updateUser(payload) {
  try {
    const { userId, values } = payload;
    const token = yield select(getToken);
    const response = yield call(updateUserCall, token, userId, values);
    yield put(userActions.successUpdateUser(normalize(response.data, user)));
    yield put(
      notificationActions.successNotification("User updated successfully!")
    );
    yield put(
      userActions.successCreateUserCloseModal(normalize(response.data, user))
    );
  } catch (e) {
    yield put(userActions.errorUpdateUser(e.response.data.errors));
    yield put(notificationActions.errorNotification("Something went wrong"));
  }
}

function* createUser(payload) {
  try {
    const { values } = payload;
    const token = yield select(getToken);
    const response = yield call(createUserApi, token, values);
    yield put(userActions.successCreateUser(normalize(response.data, user)));
    yield put(
      userActions.successCreateUserCloseModal(normalize(response.data, user))
    );
    yield put(
      notificationActions.successNotification("User created successfully!")
    );
  } catch (e) {
    yield put(userActions.errorCreateUser(e.response.data.errors));
  }
}

function* deleteUser({ userId }) {
  try {
    const token = yield select(getToken);
    yield call(deleteUserApi, token, userId);
    yield put(userActions.successDeleteUser(userId));
    yield put(userActions.successCreateUserCloseModal(userId));
  } catch (e) {
    yield put(userActions.errorDeleteUser(e));
  }
}

function* getUserPermissions({ userId }) {
  try {
    const token = yield select(getToken);
    const response = yield call(permissionsApi, token, userId);
    yield put({ type: "USER_PERMISSIONS_SUCCESS", payload: response });
  } catch (e) {
    yield put({ type: "USER_PERMISSIONS_ERROR", payload: e });
  }
}

// Saga watchers

function* watchLoadUsers() {
  while (true) {
    yield take("USERS_REQUEST");
    yield fork(getUsers);
  }
}

function* watchCurrentUser() {
  while (true) {
    yield take("CURRENT_USER_REQUEST");
    yield fork(getCurrentUser);
  }
}

function* watchUpdateUser() {
  while (true) {
    const { payload } = yield take("USER_UPDATE_REQUEST");
    yield fork(updateUser, payload);
  }
}

function* createUserFlow() {
  while (true) {
    const { payload } = yield take("USER_CREATE_REQUEST");
    yield fork(createUser, payload);
  }
}

function* watchDeleteUser() {
  while (true) {
    const { payload } = yield take("USER_DELETE_REQUEST");
    yield fork(deleteUser, payload);
  }
}

function* watchCreateUser() {
  while (true) {
    yield take("CREATE_USER");
    yield fork(createUserFlow);
  }
}

function* watchPermissions() {
  while (true) {
    const { payload } = yield take("USER_PERMISSIONS_REQUEST");
    yield fork(getUserPermissions, payload);
  }
}

export default function* root() {
  yield all([
    fork(watchLoadUsers),
    fork(watchCurrentUser),
    fork(watchUpdateUser),
    fork(watchCreateUser),
    fork(watchDeleteUser),
    fork(watchPermissions),
  ]);
}
