import { ofType, combineEpics } from "redux-observable";
import { normalize, schema } from "normalizr";
import { ajax } from "rxjs/ajax";
import {
  map,
  switchMap,
  catchError,
  pluck,
  filter,
  merge,
  mergeMap,
} from "rxjs/operators";
import * as notificationActions from "../notifications/actions";
import { URL } from "../../global/constants";

import * as vesselActions from "./actions";

import { getToken } from "../auth/reducer";
import { getVesselGA } from "./reducers";
import { createFormData } from "../utilities";

const type = new schema.Entity("type");
const account = new schema.Entity("account");
const image = new schema.Entity("image");
const flag = new schema.Entity("flag");
const views = new schema.Entity("views");
const ga = new schema.Entity("ga");
const vessel = new schema.Entity(
  "vessels",
  {
    views: [views],
    account: account,
    type: type,
    image: image,
    flag: flag,
    ga: ga,
  },
  {
    processStrategy: (entity) => {
      return {
        ...entity,
        companyName: entity.account.name || "",
        accountAlt: entity.account,
      };
    },
  }
);

const getVessels = (action$, state$) =>
  action$.pipe(
    ofType("VESSELS_REQUEST"),
    map(() => getToken(state$.value)),
    switchMap((token) =>
      ajax({
        url: `${URL}/secured/vessels`,
        headers: { Authorization: token },
      }).pipe(
        map((res) =>
          vesselActions.vesselsSuccess(normalize(res.response, [vessel]))
        ),
        catchError((e) => Promise.resolve(vesselActions.vesselsError(e)))
      )
    )
  );

const createVessel = (action$, state$) =>
  action$.pipe(
    ofType("CREATE_VESSEL_REQUEST"),
    map(({ payload }) => ({
      accessToken: getToken(state$.value),
      ...payload,
    })),

    switchMap(({ accessToken, values }) =>
      ajax({
        method: "POST",
        headers: {
          Authorization: accessToken,
        },
        body: createFormData(values),
        url: `${URL}/secured/vessels`,
      }).pipe(
        pluck("response"),
        map((response) =>
          vesselActions.createVesselSuccess(normalize(response, vessel))
        ),
        catchError((e) =>
          Promise.resolve(
            vesselActions.createVesselError({
              message: e.message,
              status: e.status,
            })
          )
        )
      )
    )
  );

// Delete Vessel epic start

const deleteVessel = (action$, state$) =>
  action$.pipe(
    ofType("VESSEL_DELETE_REQUEST"),
    map(({ payload }) => ({
      token: getToken(state$.value),
      id: payload,
    })),
    switchMap(({ token, id }) =>
      ajax({
        method: "DELETE",
        headers: {
          Authorization: token,
        },
        url: `${URL}/secured/vessels/${id}`,
      }).pipe(
        pluck("response"),
        filter((response) => response === "OK"),
        map(() => ({ type: "VESSEL_DELETE_SUCCESS", payload: id })),
        catchError((e) =>
          Promise.resolve(
            vesselActions.errorDeleteVessel({
              message: e.message,
              status: e.status,
            })
          )
        )
      )
    )
  );

// Delete Vessel epic end

//DELETE VESSEL VIEW START
const deleteVesselView = (action$, state$) =>
  action$.pipe(
    ofType("VESSEL_VIEW_DELETE_REQUEST"),
    map(({ payload }) => ({
      token: getToken(state$.value),
      id: payload,
    })),
    switchMap(({ token, id }) =>
      ajax({
        method: "DELETE",
        headers: {
          Authorization: token,
        },
        url: `${URL}/secured/vesselView/${id}`,
      }).pipe(
        pluck("response"),
        filter((response) => response.status === 200),
        mergeMap((response) => [
          { type: "VESSEL_VIEW_DELETE_SUCCESS", payload: id },
          { type: "VESSELS_REQUEST" },
        ]),
        // map(() => ({ type: "VESSEL_VIEW_DELETE_REQUEST", payload: id })),
        catchError((e) =>
          Promise.resolve(
            vesselActions.errorDeleteVessel({
              message: e.message,
              status: e.status,
            })
          )
        )
      )
    )
  );
//DELETE VESSEL VIEW END

const fetchGeneralArrangement = (action$, state$) =>
  action$.pipe(
    ofType("GENERAL_ARRANGEMENT_REQUEST"),
    map(({ payload }) => getVesselGA(state$.value, payload.vesselId).url),
    switchMap((gaUrl) =>
      ajax({
        responseType: "text",
        headers: {
          "Content-Type": `image/svg+xml`,
        },
        url: gaUrl,
      }).pipe(
        pluck("response"),
        map((res) => ({ type: "GENERAL_ARRANGEMENT_SUCCESS", payload: res }))
      )
    )
  );

const postGeneralArrangement = (action$, state$) =>
  action$.pipe(
    ofType("POST_GENERAL_ARRANGEMENT_REQUEST"),
    map(({ payload }) => ({
      accessToken: getToken(state$.value),
      ...payload,
    })),
    switchMap(({ accessToken, vesselId, file }) =>
      ajax({
        method: "POST",
        headers: {
          Authorization: accessToken,
        },
        body: createFormData({ file: file }),
        url: `${URL}/secured/vessels/${vesselId}/ga`,
      }).pipe(
        pluck("response"),
        map((res) => ({
          type: "POST_GENERAL_ARRANGEMENT_SUCCESS",
          payload: {
            response: res,
          },
        })),
        catchError((e) =>
          Promise.resolve({ type: "POST_GENERAL_ARRANGEMENT_ERROR" })
        )
      )
    )
  );

// Create Vessel View Epic start
const createVesselViews = (action$, state$) =>
  action$.pipe(
    ofType("CREATE_VESSEL_VIEW_REQUEST"),
    map(({ payload }) => {
      return {
        accessToken: getToken(state$.value),
        ...payload,
      };
    }),
    switchMap(({ accessToken, values }) => {
      return ajax({
        method: "POST",
        headers: {
          Authorization: accessToken,
        },
        body: createFormData(values),
        url: `${URL}/secured/vesselView`,
      }).pipe(
        pluck("response"),
        mergeMap((response) => [
          vesselActions.createVesselViewSuccess(normalize(response, views)),
          { type: "VESSELS_REQUEST" },
        ]),
        catchError((e) =>
          Promise.resolve(
            vesselActions.createVesselViewError({
              message: e.message,
              status: e.status,
            })
          )
        )
      );
    })
  );

//Create Vessel View Epic end
const updateVesselViews = (action$, state$) =>
  action$.pipe(
    ofType("UPDATE_VESSEL_VIEW_REQUEST"),
    map(({ payload }) => {
      return {
        accessToken: getToken(state$.value),
        ...payload,
      };
    }),
    switchMap(({ accessToken, data, viewID }) => {
      return ajax({
        method: "PUT",
        headers: {
          Authorization: accessToken,
        },
        body: createFormData(data),
        url: `${URL}/secured/vesselView/${viewID}`,
      }).pipe(
        pluck("response"),
        map((response) =>
          vesselActions.createVesselViewSuccess(normalize(response, vessel))
        ),
        catchError((e) =>
          Promise.resolve(
            vesselActions.createVesselViewError({
              message: e.message,
              status: e.status,
            })
          )
        )
      );
    })
  );

const vesselNotifications = (action$) => {
  const createVessel = action$.pipe(
    ofType("CREATE_VESSEL_SUCCESS"),
    map(({ payload }) => {
      return notificationActions.successNotification(
        `Vessel created successfuly!`
      );
    })
  );

  const deleteVessel = action$.pipe(
    ofType("VESSEL_DELETE_REQUEST"),
    map(({ payload }) => {
      return notificationActions.successNotification(
        `Vessel deleted successfuly!`
      );
    })
  );

  return createVessel.pipe(merge(deleteVessel));
};

export default combineEpics(
  getVessels,
  createVessel,
  fetchGeneralArrangement,
  postGeneralArrangement,
  updateVesselViews,
  createVesselViews,
  deleteVessel,
  vesselNotifications,
  deleteVesselView
);
