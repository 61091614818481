import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Portal from "./Portal";
import { connect } from "react-redux";

const Container = styled.div`
  overflow: scroll;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(13, 13, 38, 0.7);
  display: flex;
  justify-content: center;
  align-items: ${(props) => props.alignItems || "center"};
`;
const CloseMoadlElement = styled.span`
  position: absolute;
  z-index: 1001;
  color: #fff;
  top: ${(props) => (props.smallMargin ? "16px" : "24px")};
  right: 16px;
  cursor: pointer;
`;
const Modal = ({ children, isOpen, closeModal, alignItems, smallMargin }) => {
  const [isOpenModal, setIsOpen] = useState(isOpen);

  useEffect(() => {
    setIsOpen(isOpen);
  }, [isOpen]);
  return (
    <Portal>
      {isOpenModal && (
        <Container alignItems={alignItems || "center"}>
          <div
            onClick={(e) => e.stopPropagation()}
            style={{ position: "relative", margin: "auto" }}
          >
            <CloseMoadlElement
              onClick={() => {
                setIsOpen(!isOpenModal);
                closeModal();
              }}
              smallMargin={smallMargin}
            >
              X
            </CloseMoadlElement>
            {children}
          </div>
        </Container>
      )}
    </Portal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch({ type: "CLOSE_MODAL" }),
});

export default connect(null, mapDispatchToProps)(Modal);
