// Style helpers

export const getBehaviour = flex => {
  if (flex) {
    if (flex === "grow") {
      return "1 1 auto";
    } else if (flex === "none") {
      return "0 0 auto";
    } else {
      return "0 1 auto";
    }
  }
  return null;
};

export const justify = attribute => {
  switch (attribute) {
    case "around":
      return "space-around";
    case "between":
      return "space-between";
    case "start":
      return "flex-start";
    case "end":
      return "flex-end";
    case "center":
      return "center";
    case "evenly":
      return "space-evenly";
    default:
      return "start";
  }
};

export const getDirection = direction => {
  switch (direction) {
    case "column":
    case "col":
      return "column";
    case "row":
      return "row";
    default:
      return "row";
  }
};

export const getShape = borderStyle => {
  switch (borderStyle) {
    case "rounded":
      return "4px";
    case "circle":
      return "50%";
    case "pill":
      return "999px";
    default:
      return "0px";
  }
};

export const getPosition = position => {
  switch (position) {
    case "absolute":
      return "absolute";
    case "static":
      return "static";
    case "fixed":
      return "fixed";
    default:
      return "relative";
  }
};

export default {
  getBehaviour,
  justify,
  getDirection,
  getShape,
  getPosition
};
