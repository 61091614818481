import { ajax } from "rxjs/ajax";
import { ofType, combineEpics } from "redux-observable";
import {
  map,
  filter,
  tap,
  mapTo,
  switchMap,
  pluck,
  catchError,
} from "rxjs/operators";

import { URL } from "../../global/constants";
import * as loginActions from "./actions";
import { getToken } from "../auth/reducer";

const checkCredentials = (action$, state$) =>
  action$.pipe(
    ofType("ON_LOGIN"),
    map(({ payload }) => ({
      accessToken: getToken(state$.value),
      history: payload.history,
    })),
    filter(({ accessToken }) => accessToken),
    tap(({ history }) => {
      return history.push("/verify");
    }),
    mapTo({ type: "REDIRECT_TO_VERIFY" })
  );

const login = (action$) =>
  action$.pipe(
    ofType("LOGIN_REQUEST"),
    map(({ payload }) => ({
      username: payload.username,
      password: payload.password,
      history: payload.history,
    })),
    switchMap(({ username, password, history }) =>
      ajax({
        url: `${URL}/public/token`,
        method: "POST",
        body: {
          username,
          password,
        },
      }).pipe(
        pluck("response"),
        map((response) => ({
          type: "LOGIN_SUCCESS",
          payload: { ...response, history },
        })),
        catchError((e) =>
          Promise.resolve(
            loginActions.loginError({ ...e.response, status: e.status })
          )
        )
      )
    )
  );

const redirectToDashboard = (action$) =>
  action$.pipe(
    ofType("LOGIN_SUCCESS"),
    map(({ payload }) => payload.history),
    tap((history) => history.push("/verify")),
    mapTo({ type: "REDIRECT_TO_VERIFY" })
  );

export default combineEpics(checkCredentials, login, redirectToDashboard);
