import { ajax } from "rxjs/ajax";
import { map, switchMap, catchError, tap } from "rxjs/operators";
import { ofType, combineEpics } from "redux-observable";
// import { normalize, schema } from "normalizr";

import * as countriesActions from "./actions";

import { getToken } from "../auth/reducer";

import { URL } from "../../global/constants";

const getCountries = (action$, state$) =>
  action$.pipe(
    ofType(countriesActions.requestCountries().type),
    map(() => state$.value),
    map(getToken),
    switchMap(token =>
      ajax({
        url: `${URL}/secured/countries`,
        headers: {
          Authorization: token
        }
      }).pipe(
        // pluck("response"),
        tap(res => console.log(res)),
        map(response => ({ type: "TEST", payload: response })),
        catchError(e =>
          Promise.resolve(
            countriesActions.errorCountries({
              message: e.message,
              status: e.status
            })
          )
        )
      )
    )
  );

export default combineEpics(getCountries);
