import { connect } from "react-redux";

import VesselView from "../components/VesselView";
import { getAllNotes } from "../../../entities/notes/reducers";

const mapStateToProps = state => ({
  notes: getAllNotes(state),
  isFetching: state.notes.isFetching
});

const mapDispatchToProps = dispatch => ({
  selectNote: noteId => dispatch({ type: "SELECT_NOTE", payload: noteId }),
  reset: () => dispatch({ type: "RESET_VIEW_FLOWS" })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VesselView);
