import styled from "styled-components";

import { getPixels, getMargins } from "../styled-utils";

const Avatar = styled.div`
  display: block;
  height: ${props =>
    props.height ? getPixels(props.height) : props.big ? "64px" : "48px"};
  width: ${props =>
    props.width ? getPixels(props.width) : props.big ? "64px" : "48px"};
  border-radius: ${props =>
    props.circle ? "50%" : props.rounded ? "4px" : "0px"};
  background-image: url(${props => props.avatar && props.avatar});
  background-color: rgba(28, 28, 29, 1);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  ${getMargins};
  &:hover {
    ${props => props.onClick && "cursor: pointer;"};
  }
`;

export default Avatar;
