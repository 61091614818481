import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors, getMargins, getPaddings } from "../styled-utils";

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${props => (props.color ? colors[props.color] : colors.smoke)};
  margin-bottom: ${props => (props.noMargin ? 0 : "48px")};
  ${getMargins};
`;

export const H1 = styled.h1`
  display: block;
  font-size: 48px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: ${props => (props.light ? 300 : 500)};
  color: ${props => (props.color ? colors[props.color] : colors.smoke)};
  margin: 0;
  margin-bottom: 16px;
  ${getMargins};
  ${getPaddings};
`;

export const H2 = styled(H1)`
  font-size: 36px;
`;

export const H3 = styled(H1)`
  font-size: 32px;
`;

export const H4 = styled(H1)`
  font-size: 24px;
`;

export const Text = styled.span`
  font-size: ${props => (props.size ? props.size : "14px")};
  font-style: normal;
  font-weight: ${props => (props.weight ? props.weight : "300")};
  color: ${props => (props.color ? colors[props.color] : colors.smoke)};
  text-align: ${props => (props.center ? "center" : "left")};
  width: ${props => (props.width ? props.width : "auto")};
  ${getMargins};
  ${getPaddings};

  ${({ onClick }) => {
    if (onClick) {
      return `
        &:hover {
          cursor: pointer;
        }
      `;
    }
    return;
  }}
`;

export const Anchor = styled(Link)`
  font-size: ${props => (props.size ? props.size : "14px")};
  font-style: normal;
  font-weight: ${props => (props.weight ? props.weight : "300")};
  color: ${props => (props.color ? colors[props.color] : colors.smoke)};
  text-align: ${props => (props.center ? "center" : "left")};
  width: ${props => (props.width ? props.width : "auto")};
  text-decoration: none;
  transition: all 125ms ease-in-out;
  ${getMargins};
  ${getPaddings};

  &:hover {
    color: ${colors.white};
  }
`;

export default { Divider, H1, H2, H3, H4, Text, Anchor };
