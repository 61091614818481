import React, { useEffect } from "react";
import dayjs from "dayjs";

import { Box, Table } from "../../../components";

const VesselView = ({ notes, reset, isFetching, selectNote }) => {
  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  return (
    <Box width="100%">
      <Table slim loading={isFetching}>
        <Table.Head>
          <Table.Row>
            {[
              "ID",
              "Title",
              "Description",
              "Matterport Tag Id",
              "Created",
              "Attachment"
            ].map(item => (
              <Table.Header key={item}>{item}</Table.Header>
            ))}
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {notes.map(note => {
            const {
              id,
              matterportTagId,
              title,
              details,
              createdAt,
              attachmentUrl,
              attachmentName
            } = note;
            return (
              <Table.Row key={id} onClick={() => selectNote(id)}>
                <Table.Cell>{id}</Table.Cell>
                <Table.Cell>{title}</Table.Cell>
                <Table.Cell>{details}</Table.Cell>
                <Table.Cell>{matterportTagId}</Table.Cell>
                <Table.Cell>
                  {dayjs(createdAt).format("DD MMM YYYY")}
                </Table.Cell>
                <Table.Cell link={attachmentUrl}>{attachmentName}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </Box>
  );
};

export default VesselView;

VesselView.defaultProps = {
  notes: [],
  selectNote: () => {},
  isFetching: false
};
