import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import { Box, utils } from "../components";

const getStatus = status => {
  if (status === "error") {
    return utils.colors.crimson;
  } else if (status === "success") return utils.colors.green;
  else {
    return utils.colors.ghost;
  }
};

const HyperContainer = styled(Box)`
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const HyperBox = styled(Box)`
  position: relative;
  display: flex;
  margin-bottom: 16px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3), 0 5px 8px -4px rgba(0, 0, 0, 0.47);
  border: 2px solid ${props => getStatus(props.status)};
  font-size: 12px;
  color: ${utils.colors.offWhite};
`;

const Notifications = ({
  status,
  message,
  showNotification,
  notifications
}) => (
  <HyperContainer>
    {notifications &&
      notifications.map((notification, index) => (
        <HyperBox
          key={index}
          status={notification.status}
          background={utils.colors.darkGray}
          shape="pill"
          paddingVertical={2}
          paddingHorizontal={2}
          maxWidth="480px"
        >
          {notification.message}
        </HyperBox>
      ))}
  </HyperContainer>
);

const mapStateToProps = state => ({
  status: state.notifications.status,
  message: state.notifications.message,
  showNotification: state.notifications.length,
  notifications: state.notifications
});

export default connect(mapStateToProps)(Notifications);
