import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { createSelector } from "reselect";

import {
  Box,
  Input,
  Button,
  Dropdown2 as Dropdown,
  Avatar
} from "../../components";

import { getAllUsers } from "../../entities/users/reducer";
import { getAccountById } from "../../entities/accounts/reducer";

import AccountForm from "./AccountForm";

const UpdateAccount = ({ users, account, deleteAccount, accountId, logo }) => {
  const deleteAction = () => {
    deleteAccount(accountId);
  };
  return (
    <AccountForm title="Update Account">
      <Formik
        initialValues={{
          name: account.name,
          description: account.description
        }}
        onSubmit={values => {}}
        render={({ handleChange, handleSubmit, values, setFieldValue }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Box marginBottom={2}>
              <Avatar circle big avatar={logo} />
            </Box>
            <Box justify="between">
              <Box flex="grow" direction="col">
                <Input
                  name="name"
                  label="Account name"
                  placeholder="Enter account name..."
                  onChange={handleChange}
                  value={values.name}
                />
                <Input
                  name="description"
                  label="Description"
                  placeholder="Enter a description..."
                  onChange={handleChange}
                  value={values.description}
                />
                <Dropdown
                  name="owner"
                  label="Owner"
                  placeholder="Select owner..."
                  onChange={setFieldValue}
                  options={users}
                />
              </Box>
            </Box>
            <Box justify="end">
              <Button type="submit">Update account</Button>
              <Button
                type="button"
                danger
                marginLeft={1}
                onClick={deleteAction}
              >
                Delete account
              </Button>
            </Box>
          </form>
        )}
      />
    </AccountForm>
  );
};

const mapStateToProps = (state, { accountId }) => {
  const users = createSelector(
    [getAllUsers],
    users =>
      users.reduce((acc, user) => {
        return [...acc, { value: user.username, id: user.id }];
      }, [])
  );

  const account = getAccountById(state, accountId);
  const logo = account && account.logo;
  return {
    users: users(state),
    account: account,
    logo: logo
  };
};

const mapDispatchToProps = dispatch => ({
  updateAccount: values =>
    dispatch({ type: "ACCOUNT_TEST", payload: { values } }),
  deleteAccount: id => dispatch({ type: "ACCOUNT_DELETE_REQUEST", payload: id })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateAccount);
