import React from "react";
import styled from "styled-components";

import Box from "../box/Box";
import { colors } from "../styled-utils";

const LinkBox = styled(Box)`
  transition: all 125ms ease-in-out;

  > span {
    transition: all 125ms ease-in-out;
  }

  &:hover {
    cursor: pointer;
  }
  &:hover > span {
    color: ${colors.white};
  }
`;

const LinkItem = ({ path, history, children }) => {
  const goto = () => {
    history.push(path);
  };
  return (
    <LinkBox width="100%" direction="col" onClick={goto}>
      {children}
    </LinkBox>
  );
};

export default LinkItem;
