import React, { PureComponent } from "react";
import styled from "styled-components";

import { colors } from "../styled-utils";

const StyledTable = styled.table`
  position: relative;
  width: 100%;
  ${({ slim }) => {
    if (!slim) {
      return `
        background: ${colors.darkGray};
        background: linear-gradient(135deg, ${colors.darkGray}, ${colors.black});
      padding: 16px;
      box-shadow: 0 1px 46px -4px rgba(0, 0, 0, 0.28);
      `;
    }
    return `
    box-shadow: none;
    background: transparent;
    padding: 16px;
    `;
  }}
  border: 1px solid ${colors.lightGray};
  color: ${colors.smoke};
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 16px;
`;

const TableOverlay = styled.span`
  display: block;
  background: rgba(28, 28, 29, 0.85);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  border-radius: 4px;
`;

const StyledTableHead = styled.thead``;

const StyledTableRow = styled.tr`
  &:hover {
    cursor: pointer;
  }
`;

const StyledTableHeader = styled.th`
  font-weight: 500;
  padding-bottom: 16px;
  border-bottom: 1px solid ${colors.lightGray};
`;

const StyledTableBody = styled.tbody``;

const StyledTableCell = styled.td`
  padding: 8px 0px;

  > a {
    color: ${colors.lightblue};
    text-decoration: none;
    font-weight: 500;
  }
`;

const StyledBullet = styled.span`
  display: inline-block;
  height: 8px;
  width: 8px;
  background: ${(props) => (props.enabled ? colors.green : colors.crimson)};
  border-radius: 50%;
`;

const TableCell = ({ children, link }) => {
  if (typeof children === "boolean") {
    return (
      <StyledTableCell>
        <StyledBullet enabled={children} />
      </StyledTableCell>
    );
  } else if (link) {
    return (
      <StyledTableCell>
        {children ? (
          <a rel="noopener noreferrer" target="_blank" href={link}>
            {children}
          </a>
        ) : (
          "N/A"
        )}
      </StyledTableCell>
    );
  }
  return <StyledTableCell>{children}</StyledTableCell>;
};

class Table extends PureComponent {
  state = {};

  static Row = StyledTableRow;
  static Cell = TableCell;
  static Header = StyledTableHeader;
  static Head = StyledTableHead;
  static Body = StyledTableBody;

  render() {
    const { children, loading, slim } = this.props;
    return (
      <StyledTable slim={slim}>
        {loading && <TableOverlay>Loading...</TableOverlay>}
        {children}
      </StyledTable>
    );
  }
}

export default Table;
