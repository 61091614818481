import { connect } from "react-redux";

import Note from "../components/Note";
import { getNoteById } from "../../../entities/notes/reducers";

const mapStateToProps = state => {
  const selectedNote = state.notes.selectedNote;

  return {
    note: selectedNote && getNoteById(state, state.notes.selectedNote),
    hasNote: !!selectedNote
  };
};
const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Note);
