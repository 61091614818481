export const createFormData = values => {
  let formData = new FormData();

  for (let i = 0; i < Object.keys(values).length; i++) {
    const field = Object.keys(values)[i];
    formData.set(field, values[field]);
  }

  return formData;
};
