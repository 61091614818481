import { connect } from "react-redux";
import { getVesselById } from "../../../entities/vessels/reducers";

import VesselViews from "../components/VesselViews";
import { vesselsRequest } from "../../../entities/vessels/actions";
import * as vesselActions from "../../../entities/vessels/actions";

const getVesselViewIds = (state, vesselId) =>
  getVesselById(state, vesselId).views.map((view) => state.views.byId[view]);

const mapStateToProps = (state, ownProps) => ({
  vesselViews: getVesselViewIds(state, ownProps.vesselId),
  showCreatePanel: state.ui.vesselView.createVesselView,
  vesselId: ownProps.vesselId,
});

const mapDispatchToProps = (dispatch) => ({
  selectView: (viewId) => dispatch({ type: "SELECT_VIEW", payload: viewId }),
  updateView: (data, viewID) =>
    dispatch(vesselActions.createVesselViewRequest({ data, viewID })),
  createVesselView: () => dispatch({ type: "CREATE_VESSEL_VIEW" }),
  getVessels: () => dispatch({ type: "VESSELS_REQUEST" }),
  deleteView: (viewId) =>
    dispatch({ type: "VESSEL_VIEW_DELETE_REQUEST", payload: viewId }),
});

export default connect(mapStateToProps, mapDispatchToProps)(VesselViews);
