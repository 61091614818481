import axios from "axios";
import {URL} from "../global/constants";

export async function getPermissionListById (id, token) {
  return axios.get(`${URL}/secured/permissions/${id}`, {
    headers: {
      "Authorization": token
    }
  })
}

export async function addPermission (id, data, token) {
  return axios.post(`${URL}/secured/permissions/${id}`,data,  {
    headers: {
      "Authorization": token
    }
  })
}

export async function deletePermission (id, token) {
  return axios.delete(`${URL}/secured/permissions/${id}`,  {
    headers: {
      "Authorization": token
    }
  })
}
