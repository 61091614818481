import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import { getToken } from "../entities/auth/reducer";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const { token } = rest;
      return token ? <Component {...props} /> : <Redirect to="/login" />;
    }}
  />
);

const mapStateToProps = state => ({
  token: getToken(state)
});

export default connect(mapStateToProps)(PrivateRoute);
