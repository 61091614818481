// Javascript helpers
const contains = (key, arr) => arr.indexOf(key) >= 0;
export const omit = (keys, obj) =>
  Object.keys(obj).reduce((acc, k) => {
    if (contains(k, keys)) {
      return acc;
    }
    return {
      ...acc,
      [k]: obj[k]
    };
  }, {});
