import React, { PureComponent } from "react";
import styled from "styled-components";

import * as utils from "../styled-utils";

const StyledSelect = styled.select`
  width: 100%;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-size: 14px;
  font-style: normal;

  padding: 16px;
  border-radius: 999px;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: none;
  border: 2px solid ${utils.colors.ghost};
  color: ${utils.colors.offWhite};
  background: transparent;
`;

const StyledOption = styled.option`
  background: ${utils.colors.darkGray};
  border-radius: 999px;
  border: none;
`;

class Select extends PureComponent {
  state = { value: null };

  render() {
    const { options } = this.props;
    const { value } = this.state;
    return (
      <StyledSelect value={value} onChange={() => 0}>
        {options.map(option => (
          <StyledOption value={option.value}>{option.name}</StyledOption>
        ))}
      </StyledSelect>
    );
  }
}

export default Select;
