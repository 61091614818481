import { connect } from "react-redux";

import {
  getVesselById,
  getVesselImage,
} from "../../../entities/vessels/reducers";

import Vessel from "../components/Vessel";
import { getToken } from "../../../entities/auth/reducer";

const mapStateToProps = (state, ownProps) => ({
  vessel: getVesselById(state, ownProps.vesselId),
  avatar: getVesselImage(state, ownProps.vesselId),
  token: getToken(state),
  editView: state.views.ui.editView && state.views.ui.editView.isOpen,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Vessel);
