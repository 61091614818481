const notifications = (state = [], action) => {
  switch (action.type) {
    case "NOTIFICATION_SUCCESS":
      return [
        ...state,
        {
          status: "success",
          message: action.payload.message
        }
      ];
    case "NOTIFICATION_ERROR":
      return [
        ...state,
        {
          status: "error",
          message: action.payload.message
        }
      ];
    case "NOTIFICATIONS_CLEAR":
      return [...state.slice(0, -1)];
    // return {
    //   status: null,
    //   message: null
    // };
    default:
      return state;
  }
};

export default notifications;
