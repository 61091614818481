import ReactDOM from "react-dom";
import { useEffect, useState, useRef } from "react";

export const usePortal = () => {
  const rootElemRef = useRef(null);
  const [isOpen] = useState(false);
  useEffect(() => {
    const parentEl = document.getElementById("modal");
    parentEl.appendChild(rootElemRef.current);

    return () => {
      rootElemRef.current.remove();
      if (parentEl.childNodes.length === -1) {
        parentEl.remove();
      }
    };
  }, []);

  const getRootElem = () => {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement("div");
    }
    return rootElemRef.current;
  };

  return {
    isOpen,
    target: getRootElem()
  };
};

const Portal = ({ children }) => {
  const { target } = usePortal();
  return ReactDOM.createPortal(children, target);
};
export default Portal;
