import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import DropToUpload from "react-drop-to-upload";

import {
  Box,
  Input,
  Button,
  Table,
  Checkbox,
  Avatar,
  Tabs,
  Dropdown,
  Modal,
} from "../../components";
import * as notificationActions from "../../entities/notifications/actions";
import { store } from "react-notifications-component";

import { getUserById } from "../../entities/users/reducer";

import DeleteComfirmation from "./DeleteConfirmation";
import { completeOrderById, getOrderListById } from "../../api/orders";
import { deletePermission, getPermissionListById } from "../../api/permissions";
import CreateUserBox from "./CreateUserBox";
import AddPermissionBox from "./AddPermisionBox";
import { entitySearch } from "../../entities";
import { getAllVessels } from "../../entities/vessels/reducers";

const labels = [
  { label: "Edit User" },
  { label: "Edit Permissions" },
  { label: "Orders" },
];

class User extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      permissions: [],
    };
  }

  componentDidMount() {
    getOrderListById(this.props.user.id, this.props.token).then((res) => {
      this.setState({
        ...this.state,
        orders: res.data,
      });
    });

    getPermissionListById(this.props.user.id, this.props.token).then((res) => {
      this.setState({
        ...this.state,
        permissions: res.data,
      });
    });
  }

  deleteUser = (event) => {
    const { user, deleteUser } = this.props;
    deleteUser(user.id);
  };

  handleDropDataURI = (dataURIs, files, setFieldValue) => {
    setFieldValue("avatar", files[0]);
  };

  onCompleteButtonClick = (id) => {
    completeOrderById(id, this.props.token).then((res) => {
      if (res.data === 1) {
        let tempOrders = this.state.orders;
        tempOrders = tempOrders.map((order) => {
          if (order.id === id) {
            order.status = "COMPLETE";
          }
          return order;
        });
        this.setState({
          ...this.state,
          orders: tempOrders,
        });
      }
    });
  };

  handlePermDelete = (id) => {
    deletePermission(id, this.props.token).then((res) => {
      if (res.data === 1) {
        let tempPerms = this.state.permissions;
        tempPerms = tempPerms.filter((perm) => perm.id !== id);
        this.setState({
          ...this.state,
          permissions: tempPerms,
        });
        store.addNotification({
          title: "Success",
          message: "You successfully deleted permission",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeInRight"],
          animationOut: ["animate__animated", "animate__fadeOutRight"],
          dismiss: {
            duration: 5000,
            delay: 1000,
            showIcon: true,
          },
          slidingEnter: {
            duration: 2000,
          },
        });
      }
    });
  };

  render() {
    const { user, avatar, logo, accountList, vessels } = this.props;

    const { orders, permissions } = this.state;
    return (
      <Tabs labels={labels}>
        <Box width="100%" direction="col" alignItems="start">
          <Formik
            initialValues={{
              username: user.username,
              fullname: user.fullname,
              email: user.email,
              mobile: user.mobile,
              isActive: user.isActive,
              isEnabled: user.isEnabled,
              password: "",
              rpassword: "",
              avatar: avatar || logo,
              account: user.account_id,
            }}
            onSubmit={(values) => {
              const { user, updateUser } = this.props;
              const { requiresSMS, ...noSMS } = values;
              updateUser(user.id, noSMS);
            }}
            render={({ values, handleChange, handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <Box marginBottom={2}>
                  <DropToUpload
                    onDropDataURI={(dataURIs, files) =>
                      this.handleDropDataURI(dataURIs, files, setFieldValue)
                    }
                  >
                    <Avatar circle big avatar={avatar || logo} />
                  </DropToUpload>
                </Box>

                <Box width="100%" justify="between">
                  <Box direction="col" basis="45%">
                    <Input
                      name="username"
                      label="Username"
                      type="text"
                      value={values.username}
                      onChange={handleChange}
                    />

                    <Input
                      name="password"
                      label="New password"
                      type="password"
                      value={values.password}
                      onChange={handleChange}
                    />
                    <Input
                      name="rPassword"
                      label="Repeat password"
                      type="password"
                      value={values.rPassword}
                      onChange={handleChange}
                    />
                    <Dropdown
                      label="Account list"
                      name="account"
                      defaultValue={user.account_name}
                      options={accountList}
                      onChange={(field, value) => {
                        setFieldValue(field, value.id);
                      }}
                      placeholder="Select Model..."
                    />
                  </Box>
                  <Box direction="col" basis="45%">
                    <Input
                      name="fullname"
                      label="Full name"
                      type="text"
                      value={values.fullname}
                      onChange={handleChange}
                    />

                    <Input
                      name="email"
                      label="Email"
                      type="text"
                      value={values.email}
                      onChange={handleChange}
                    />

                    <Input
                      name="mobile"
                      label="Mobile"
                      type="text"
                      value={values.mobile}
                      onChange={handleChange}
                    />
                  </Box>
                </Box>
                <Box
                  marginBottom={2}
                  justify="between"
                  alignItems="flex-end"
                  width="100%"
                >
                  <Box direction="col" alignItems="flex-start">
                    <Checkbox
                      name="isActive"
                      label="is Active?"
                      onChange={handleChange}
                      checked={values.isActive}
                    />
                    <Checkbox
                      name="isEnabled"
                      label="is Enabled?"
                      onChange={handleChange}
                      checked={values.isEnabled}
                    />
                  </Box>
                  <Box>
                    <DeleteComfirmation
                      username={user.username}
                      onConfirm={this.deleteUser}
                    />
                    <Button type="submit">Save</Button>
                  </Box>
                </Box>
              </form>
            )}
          />
        </Box>

        <Box width="100%" direction="column">
          <div className="add-button-container">
            <AddPermissionBox
              self={this}
              userId={user.id}
              vessels={vessels.filter(
                (ves) => ves.accountId === user.account_id
              )}
            />
          </div>
          <Table>
            <Table.Head>
              <Table.Row>
                {[
                  "ID",
                  "Vessel",
                  "Vessel View",
                  "Can view",
                  "Can edit",
                  "Can delete",
                  "Can share",
                  "Action",
                ].map((item) => (
                  <Table.Header key={item}>{item}</Table.Header>
                ))}
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {permissions.map((perm) => {
                const { id } = perm;
                const vessel = perm.vesselView.vessel.name;
                const view = perm.vesselView.title;
                const canView = perm.view;
                const canEdit = perm.edit;
                const canDelete = perm.delete;
                const canShare = perm.share;
                return (
                  <Table.Row key={id}>
                    <Table.Cell>{id}</Table.Cell>
                    <Table.Cell>{vessel}</Table.Cell>
                    <Table.Cell>{view}</Table.Cell>
                    <Table.Cell>{canView}</Table.Cell>
                    <Table.Cell>{canEdit}</Table.Cell>
                    <Table.Cell>{canDelete}</Table.Cell>
                    <Table.Cell>{canShare}</Table.Cell>
                    <Table.Cell>
                      <Button
                        danger
                        onClick={() => {
                          this.handlePermDelete(id);
                        }}
                      >
                        Delete
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Box>
        <Box width="100%">
          <Table>
            <Table.Head>
              <Table.Row>
                {[
                  "ID",
                  "Vessel Name",
                  "Vessel View",
                  "Model",
                  "Order Status",
                ].map((item) => (
                  <Table.Header key={item}>{item}</Table.Header>
                ))}
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {orders.map((order) => {
                const { id, status } = order;
                const vessel = order.vessel.name;
                const view = order.vesselView.title;
                const model = order.model.name;
                return (
                  <Table.Row key={id}>
                    <Table.Cell>{id}</Table.Cell>
                    <Table.Cell>{vessel}</Table.Cell>
                    <Table.Cell>{view}</Table.Cell>
                    <Table.Cell>{model}</Table.Cell>
                    <Table.Cell>
                      {status === "PENDING" ? (
                        <Button
                          onClick={() => {
                            this.onCompleteButtonClick(id);
                          }}
                        >
                          Complete
                        </Button>
                      ) : (
                        <span>Completed</span>
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Box>
      </Tabs>
    );
  }
}

const mapStateToProps = (state, props) => {
  const user = getUserById(state, props.userId);
  const token = state.auth.token;
  const avatar = user && user.avatar;
  const logo = user && user.logo;
  const vessels = entitySearch(state)(
    getAllVessels,
    (state) => state.vessels.search,
    ["name", "companyName"]
  )(state).map((ves) => {
    ves.value = ves.name;
    return ves;
  });
  const accountList = [];
  for (let key in state.accounts.byId) {
    if (state.accounts.byId.hasOwnProperty(key)) {
      state.accounts.byId[key].value = state.accounts.byId[key].name;
      accountList.push(state.accounts.byId[key]);
    }
  }
  return {
    vessels,
    user,
    avatar,
    logo,
    token,
    accountList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUser: (userId, values) =>
    dispatch({ type: "USER_UPDATE_REQUEST", payload: { userId, values } }),
  deleteUser: (userId) =>
    dispatch({ type: "USER_DELETE_REQUEST", payload: { userId } }),
});

export default connect(mapStateToProps, mapDispatchToProps)(User);
