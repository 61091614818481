const errors = (state = null, action) => {
  const { type, payload } = action;
  const matches = /(.*)_(REQUEST|ERROR)/.exec(type);
  if (!matches) return state;
  const [, requestName, requestState] = matches;
  return requestState === "ERROR"
    ? { ...state, [requestName]: payload }
    : state;
};

export const getErrors = state => {
  if (state.errors) {
    return Object.keys(state.errors).map(errorKey => {
      return state.errors[errorKey];
    });
  }
  return null;
};

export default errors;
