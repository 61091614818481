import { combineReducers } from "redux";

const token = (state = null, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      const { token } = action.payload;
      return token;
    case "LOGIN_ERROR":
      return null;
    default:
      return state;
  }
};

const isFetching = (state = false, action) =>
  action.type === "LOGIN_REQUEST" ? true : false;

const error = (state = false, action) => {
  switch (action.type) {
    case "LOGIN_ERROR":
      return action.payload;
    case "LOGIN_SUCCESS":
    case "LOGIN_REQUEST":
      return false;
    default:
      return state;
  }
};
const auth = combineReducers({
  token,
  isFetching,
  error
});

export default auth;

export const getToken = state => state.auth.token;
export const isAuthorized = state =>
  state.auth.error.status !== 401 ? true : false;
export const isLoading = state => state.auth.isFetching;
