import { connect } from "react-redux";

import { getVesselGA } from "../../../entities/vessels/reducers";

import GeneralArrangement from "../components/GeneralArrangement";

const mapStateToProps = (state, ownProps) => ({
  ga: state.vessels.ga,
  gaFilename: getVesselGA(state, ownProps.vesselId).filename,
  loading: state.vessels.isFetching.ga
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  uploadGa: file =>
    dispatch({
      type: "POST_GENERAL_ARRANGEMENT_REQUEST",
      payload: { vesselId: ownProps.vesselId, file: file }
    }),
  fetchGa: () =>
    dispatch({
      type: "GENERAL_ARRANGEMENT_REQUEST",
      payload: { vesselId: ownProps.vesselId }
    }),
  clearGa: () => dispatch({ type: "CLEAR_GA" })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralArrangement);
