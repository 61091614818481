import axios from "axios";
import {URL} from "../global/constants";

export async function getOrderListById (id, token) {
  return axios.get(`${URL}/secured/order/list/${id}`, {
    headers: {
      "Authorization": token
    }
  })
}

export async function completeOrderById (id, token) {
  return axios.put(`${URL}/secured/order/complete/${id}`,null,  {
    headers: {
      "Authorization": token
    }
  })
}
