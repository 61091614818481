import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import dayjs from "dayjs";

import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { faPlus, faRedo } from "@fortawesome/free-solid-svg-icons";

import { Box, Input, Table, ButtonIcon, Modal } from "../../components";
import * as utils from "../../components/styled-utils";
import { getAllVessels } from "../../entities/vessels/reducers";
import { entitySearch } from "../../entities";
import { getVesselFlagsList, getVesselTypesList } from "../../api/vessels";
import Vessel from "./containers/Vessel";
import CreateVessel from "./containers/CreateVessel";
import { getToken } from "../../entities/auth/reducer";

const Vessels = ({
  getVessels,
  getVesselTypes,
  allVesselTypes,
  vessels,
  search,
  isFetching,
  reset,
  updateVessel,
  showUpdatePanel,
  showCreatePanel,
  createVessel,
  token,
  accountList,
  deleteVessel,
}) => {
  const [selectedVessel, setSelectedVessel] = useState(null);

  const [vesselTypesList, setVesselTypesList] = useState();
  const [flagsList, setFlagsList] = useState();

  useEffect(() => {
    getVesselFlagsList(token).then((res) => {
      const { data } = res;
      if (data.length) {
        const modifiedFlagArray = data.map((flag) => ({
          ...flag,
          value: flag.description,
        }));
        setFlagsList(modifiedFlagArray);
      }
    });
    getVesselTypesList(token).then((res) => {
      const { data } = res;
      if (data.length) {
        const modifiedTypesArray = data.map((types) => ({
          ...types,
          value: types.description,
        }));
        setVesselTypesList(modifiedTypesArray);
      }
    });
  }, []);

  useEffect(() => {
    return () => reset();
  }, []);
  useEffect(() => {
    getVesselTypes();
  }, []);
  const handleSearch = (event) => {
    event.preventDefault();
    search(event.target.value);
  };

  const selectRow = (event, vesselId) => {
    event.preventDefault();
    setSelectedVessel(vesselId);
    updateVessel(vesselId);
  };

  return (
    <Box direction="col" width="100%" alignItems="flex-end">
      <Box
        width="100%"
        justify="between"
        alignItems="flex-start"
        marginBottom={1}
      >
        <Box>
          <ButtonIcon onClick={createVessel}>
            <FA icon={faPlus} color={utils.colors.smoke} size="xs" />
          </ButtonIcon>
          <ButtonIcon onClick={getVessels}>
            <FA icon={faRedo} color={utils.colors.smoke} size="xs" />
          </ButtonIcon>
        </Box>
        <Box basis="320px">
          <Input
            type="text"
            name="search"
            onChange={handleSearch}
            placeholder="Search vessels..."
          />
        </Box>
      </Box>
      <Table loading={isFetching}>
        <Table.Head>
          <Table.Row>
            {[
              "ID",
              "Vessel name",
              "Company",
              "IMO",
              "Date created",
              "Enabled",
            ].map((item) => (
              <Table.Header key={item}>{item}</Table.Header>
            ))}
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {vessels.map((vessel) => {
            const { id, name, imo, createdAt, enabled, companyName } = vessel;
            return (
              <Table.Row key={id} onClick={(event) => selectRow(event, id)}>
                <Table.Cell>{id}</Table.Cell>
                <Table.Cell>{name || ""}</Table.Cell>
                <Table.Cell>{companyName || ""}</Table.Cell>
                <Table.Cell>{imo}</Table.Cell>
                <Table.Cell>
                  {dayjs(createdAt).format("DD MMM YYYY")}
                </Table.Cell>
                <Table.Cell>{enabled ? true : false}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      {showUpdatePanel && (
        <Vessel
          vesselId={selectedVessel}
          key={selectedVessel}
          accountList={accountList}
          vesselTypesList={vesselTypesList}
          flagsList={flagsList}
          deleteVessel={deleteVessel}
        />
      )}
      {showCreatePanel && (
        <Modal alignItems="center" isOpen={showCreatePanel}>
          <CreateVessel
            accountList={accountList}
            vesselTypesList={vesselTypesList}
            flagsList={flagsList}
          />
        </Modal>
      )}
    </Box>
  );
};

Vessels.defaultProps = {
  vessels: [],
  isFetching: false,
};

const mapStateToProps = (state) => {
  const vessels = entitySearch(state)(
    getAllVessels,
    (state) => state.vessels.search,
    ["name", "companyName"]
  );
  const accountList = [];
  for (let key in state.accounts.byId) {
    if (state.accounts.byId.hasOwnProperty(key)) {
      state.accounts.byId[key].value = state.accounts.byId[key].name;
      accountList.push(state.accounts.byId[key]);
    }
  }

  return {
    vessels: vessels(state),
    isFetching: state.vessels.isFetching.allVessels,
    showUpdatePanel: state.ui.vessels.updateVessel,
    showCreatePanel: state.ui.vessels.createVessel,
    allVesselTypes: state.vesselTypes.allVesselTypes,
    token: getToken(state),
    accountList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateVessel: (vesselId) =>
    dispatch({ type: "UPDATE_VESSEL", payload: vesselId }),
  deleteVessel: (id) =>
    dispatch({ type: "VESSEL_DELETE_REQUEST", payload: id }),
  createVessel: () => dispatch({ type: "CREATE_VESSEL" }),
  getVessels: () => dispatch({ type: "VESSELS_REQUEST" }),
  getVesselTypes: () => dispatch({ type: "VESSEL_TYPES_REQUEST" }),
  search: (value) => dispatch({ type: "VESSELS_SEARCH", payload: { value } }),
  reset: () => dispatch({ type: "RESET_VESSEL_FLOWS" }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Vessels);
