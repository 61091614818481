import React from "react";
import styled from "styled-components";
// import matchSorter from "match-sorter";
import Downshift from "downshift";

import * as utils from "../styled-utils";

import arrowDown from "../../assets/arrow-down.svg";

import { Label } from "../inputs/Input";

const Dropdown = styled.input.attrs({
  autoComplete: "new-password",
  type: "text"
})`
  width: 100%;
  padding: 12px 10px;
  background-image: url(${arrowDown});
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: calc(100% - 10px) 50%;
  background-color: rgba(28, 28, 28, 0.5);
  border: 1px solid ${utils.colors.lightGray};
  border-radius: 4px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-style: normal;
  color: ${utils.colors.offWhite};
  outline: 0;
  box-shadow: none;
  -webkit-appearance: none;
  position: relative;
  transition: all 0.3s cubic-bezier(0.45, 0.24, 0.66, 0.89);

  &:focus {
    border: 1px solid ${utils.colors.lightblue};
  }
`;

const Dropdown2 = ({ onChange, name, placeholder, label, ...rest }) => {
  const handleChange = selection => {
    onChange(name, selection);
  };

  const handleFocus = event => {
    if (rest.hasOwnProperty("onFocus")) {
      const { onFocus } = rest;
      onFocus(event);
    }
  };

  const handleBlur = event => {
    if (rest.hasOwnProperty("onBlur")) {
      const { onBlur } = rest;
      onBlur(event);
    }
  };

  return (
    <Downshift
      itemToString={item => (item ? item.value : "")}
      onChange={handleChange}
    >
      {({
        getInputProps,
        getLabelProps,
        getMenuProps,
        getItemProps,
        isOpen,
        inputValue,
        highlightedIndex,
        toggleMenu
      }) => (
        <div style={{ display: "relative", width: "100%", marginBottom: 24 }}>
          <Label {...getLabelProps()} onClick={toggleMenu}>
            {label}
          </Label>
          <Dropdown
            {...getInputProps()}
            id={name}
            name={name}
            type="text"
            color="white"
            autoComplete="new-password"
            placeholder={placeholder}
            onFocus={handleFocus}
            onBlur={handleBlur}
            Role="presentation"
            onClick={toggleMenu}
          />
          {isOpen && (
            <ul
              {...getMenuProps({
                style: {
                  listStyle: "none",
                  margin: 0,
                  marginTop: 8,
                  border: `1px solid ${utils.colors.lightGray}`,
                  borderRadius: 4,
                  position: "absolute",
                  width: "100%",
                  zIndex: 999,
                  padding: "8px 0px",
                  background: "rgb(28, 28, 28)",
                  fontWeight: 400,
                  fontSize: 14
                }
              })}
            >
              {rest.options
                .filter(item => !inputValue || item.value.includes(inputValue))
                .map((item, index) => {
                  return (
                    <li
                      {...getItemProps({
                        key: item.value,
                        index,
                        item,
                        style: {
                          cursor: "pointer",
                          color:
                            highlightedIndex === index
                              ? utils.colors.lightblue
                              : utils.colors.offWhite,
                          backgroundColor:
                            highlightedIndex === index
                              ? utils.colors.lightGray
                              : "transparent",
                          fontSize: 14,
                          padding: 8
                        }
                      })}
                    >
                      {item.value}
                    </li>
                  );
                })}
            </ul>
          )}
        </div>
      )}
    </Downshift>
  );
};

export default Dropdown2;
