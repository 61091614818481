const getUrl = () => {
  const HOSTNAME = window.location.hostname;
  switch (HOSTNAME) {
    case "www.vesselfront.com":
    case "admin.vesselfront.com":
      return `https://api.vesselfront.com`;
    case "admin.staging.vesselfront.com":
      return "http://api.staging.vesselfront.com";
    case "admin.dev.vesselfront.com":
      return "http://api.dev.vesselfront.com";
    default:
      return "http://localhost:8080";
  }
};

export const URL = getUrl();
