import { combineReducers } from "redux";

const users = (state = {}, action) => {
  switch (action.type) {
    case "CREATE_USER":
      return {
        ...state,
        createUser: true,
        updateUser: false,
      };
    case "UPDATE_USER":
      return {
        ...state,
        createUser: false,
        updateUser: true,
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        createUser: false,
        updateUser: false,
      };
    case "RESET_USER_FLOWS":
    case "USERS_SEARCH":
      return {
        ...state,
        createUser: false,
        updateUser: false,
      };
    default:
      return state;
  }
};

const accounts = (state = {}, action) => {
  switch (action.type) {
    case "CREATE_ACCOUNT":
      return {
        ...state,
        createAccount: true,
        updateAccount: false,
      };
    case "UPDATE_ACCOUNT":
      return {
        ...state,
        createAccount: false,
        updateAccount: true,
      };
    case "CREATE_ACCOUNT_SUCCESS":
    case "ACCOUNT_DELETE_SUCCESS":
    case "ACCOUNTS_SEARCH":
    case "RESET_ACCOUNT_FLOWS":
      return {
        ...state,
        createAccount: false,
        updateAccount: false,
      };
    default:
      return state;
  }
};

const vessels = (state = {}, action) => {
  switch (action.type) {
    case "CREATE_VESSEL":
      return {
        ...state,
        createVessel: true,
        updateVessel: false,
      };
    case "UPDATE_VESSEL":
      return {
        ...state,
        createVessel: false,
        updateVessel: true,
      };
    case "CREATE_VESSEL_SUCCESS":
    case "VESSELS_SEARCH":
    case "VESSELS_REQUEST":
    case "RESET_VESSEL_FLOWS":
      return {
        ...state,
        createVessel: false,
        updateVessel: false,
      };
    default:
      return state;
  }
};

// Vessel View Ui start

const vesselView = (state = {}, action) => {
  switch (action.type) {
    case "CREATE_VESSEL_VIEW":
      return {
        ...state,
        createVesselView: true,
        updateVesselView: false,
      };
    case "UPDATE_VESSEL_VIEW":
      return {
        ...state,
        createVesselView: false,
        updateVesselView: true,
      };
    case "CREATE_VESSEL_VIEW_SUCCESS":
    case "RESET_VESSEL_VIEW_FLOWS":
    case "VESSEL_VIEW_SEARCH":
      return {
        ...state,
        createVesselView: false,
        updateVesselView: false,
      };
    default:
      return state;
  }
};

// Vessel View UI end

const ui = combineReducers({
  accounts,
  users,
  vessels,
  vesselView,
});

export default ui;
