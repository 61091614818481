export const accountsRequest = () => ({ type: "ACCOUNTS_REQUEST" });
export const accountsSuccess = ({ entities, result }) => ({
  type: "ACCOUNTS_SUCCESS",
  payload: { entities, result }
});
export const accountsError = error => ({
  type: "ACCOUNTS_ERROR",
  payload: { ...error }
});

export const searchAccounts = value => ({
  type: "ACCOUNTS_SEARCH",
  payload: { value }
});

export const requestCreateAccount = values => ({
  type: "CREATE_ACCOUNT_REQUEST",
  payload: values
});
export const successCreateAccount = ({ entities, result }) => ({
  type: "CREATE_ACCOUNT_SUCCESS",
  payload: { entities, result }
});
export const errorCreateAccount = error => ({
  type: "CREATE_ACCOUNT_ERROR",
  payload: error
});

export const requestUpdateAccount = values => ({
  type: "UPDATE_ACCOUNT_REQUEST",
  payload: values
});
export const successUpdateAccount = ({ entities, result }) => ({
  type: "UPDATE_ACCOUNT_SUCCESS",
  payload: { entities, result }
});
export const errorUpdateAccountt = error => ({
  type: "UPDATE_ACCOUNT_ERROR",
  payload: error
});

export const requestAccountDelete = id => ({
  type: "ACCOUNT_DELETE_REQUEST",
  payload: id
});

export const successAccountDelete = ({ entities, result }) => ({
  type: "ACCOUNT_DELETE_SUCCESS",
  payload: { entities, result }
});

export const errorDeleteAccount = error => ({
  type: "ACCOUNT_DELETE_ERROR",
  payload: error
});
