import React, {useEffect, useState} from "react";
import styled from "styled-components";

import {Box, Text, Input, Button, Portal, Dropdown, Checkbox} from "../../components";
import { colors } from "../../components/styled-utils";
import {Formik} from "formik";
import { connect } from "react-redux";
import axios from "axios";
import {getToken} from "../../entities/auth/reducer";
import {addPermission} from "../../api/permissions";
import { store } from "react-notifications-component";

const Container = styled.div`
  position: absolute;
  z-index: 1000000000;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(13, 13, 38, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddPermissionBox = ({vessels, vesselViews, token, userId, self}) => {
  const [isOpen, setIsOpen] = useState(null);
  const [selectedVessel, setSelectedVessel] = useState();
  const [selectedView, setSelectedView] = useState();
  const [viewList, setViewList] = useState([]);

  const handleSubmit = (values, { resetForm }) => {
    const data = {
      vesselViewId: values.vessel_view.id,
      edit: values.edit,
      delete: values.delete,
      share: values.share,
      view: values.view,
    }
    addPermission(userId, data, token).then((res) => {
      if (res.status === 200) {
        resetForm();
        let tempPerms = self.state.permissions;
        if (tempPerms.find(perm => perm.id === res.data.id)) {
          tempPerms = tempPerms.map(perm => {
            if (perm.id === res.data.id) {
              return res.data;
            }
            return perm;
          })
        } else {
          tempPerms = [...tempPerms, res.data];
        }
        self.setState({
          ...self.state,
          permissions: tempPerms
        })
        setSelectedVessel(null);
        setSelectedView(null);
        store.addNotification({
          title: "Success",
          message: "You successfully added permission",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeInRight"],
          animationOut: ["animate__animated", "animate__fadeOutRight"],
          dismiss: {
            duration: 5000,
            delay: 1000,
            showIcon: true,
          },
          slidingEnter: {
            duration: 2000,
          },
        });
      }
      setIsOpen(false);
    });
  };

  useEffect(() => {
    if (selectedVessel) {
      setViewList(selectedVessel.views.map(view => {
        vesselViews[view].value = vesselViews[view].title;
        return vesselViews[view];
      }));
    } else {
      setViewList([]);
    }
  }, [selectedVessel]);

  return (
    <>
      <Button
        type="button"
        marginRight={1}
        onClick={() => setIsOpen(true)}
      >
        Add Permission
      </Button>
      <Portal>
        {isOpen && (
          <Container onClick={() => setIsOpen(false)}>
            <div onClick={e => e.stopPropagation()}>
              <Box
                paddingVertical={2}
                paddingHorizontal={2}
                background={colors.darkGray}
                shape="rounded"
                elevate
                direction="col"
                alignItems="start"
                maxWidth={320}
              >
                <Formik
                  onSubmit={handleSubmit}
                  initialValues={{
                    edit: false,
                    view: false,
                    delete: false,
                    share: false,
                  }}
                  validate={(values) => {
                    let errors = {};
                    if (!values.vessel ) {
                      errors.vessel = "Required";
                    }
                    if (!values.vessel_view ) {
                      errors.vessel_view = "Required";
                    }
                    if (!(values.edit || values.view || values.delete || values.share)) {
                      errors.edit = "Required";
                    }
                    return errors;
                  }}
                  render={({ values, handleSubmit, handleChange, setFieldValue, isValid, resetForm }) => (
                    <form onSubmit={handleSubmit}>

                      {vessels && (
                        <Dropdown
                          label="Select vessel"
                          name="vessel"
                          options={vessels}
                          onChange={(field, value) => {
                            setSelectedVessel(value);
                            setFieldValue(field, value);
                            setFieldValue('vessel_view', null);
                            setSelectedView(null);
                          }}
                          placeholder="Select vessel..."
                        />
                      )}
                      {selectedVessel && (
                        <Dropdown
                          label="Select vessel view"
                          name="vessel_view"
                          options={viewList}
                          onChange={(field, value) => {
                            setSelectedView(value);
                            setFieldValue(field, value);
                          }}
                          placeholder="Select vessel view..."
                        />
                      )}
                      {selectedView &&
                      <Box direction="column" justify="flex-start" alignItems="">
                        <div className="checkbox-wrapper">
                          <Checkbox
                            name="view"
                            label="Can view"
                            onChange={handleChange}
                            checked={values.view}
                          />
                          <Checkbox
                            name="edit"
                            label="Can edit"
                            onChange={handleChange}
                            checked={values.edit}
                          />
                          <Checkbox
                            name="delete"
                            label="Can delete"
                            onChange={handleChange}
                            checked={values.delete}
                          />
                          <Checkbox
                            name="share"
                            label="Can share"
                            onChange={handleChange}
                            checked={values.share}
                          />
                        </div>
                      </Box>
                      }
                      <Box display="flex" justify="center" alignItems="center">

                        <Button type="submit" rounded secondary disabled={!isValid}>
                          Add
                        </Button>
                      </Box>
                    </form>
                  )}
                />
              </Box>
            </div>
          </Container>
        )}
      </Portal>
    </>
  );
};

const mapStateToProps = (state) => ({
  token: getToken(state),
  vesselViews: state.views.byId
});

export default connect(mapStateToProps)(AddPermissionBox);
