import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import {
  Box,
  Input,
  Button,
  Avatar,
  Divider,
  H4,
  Dropdown,
} from "../../components";
import { colors } from "../../components/styled-utils";
import { getUserById } from "../../entities/users/reducer";
import DropToUpload from "react-drop-to-upload";

class CreateUserBox extends PureComponent {
  state = { thumb: null };

  handleDropDataURI = (dataURIs, files, setFieldValue) => {
    this.setState({ thumb: dataURIs[0] });
    setFieldValue("avatar", files[0]);
  };

  validateForm = (values) => {
    let errors = {};
    if (!values.username) {
      errors.username = "Required";
    }
    if (!values.password) {
      errors.password = "Required";
    }
    if (!values.rpassword) {
      errors.rpassword = "Required";
    }
    if (
      values.password &&
      values.rpassword &&
      values.password !== values.rpassword
    ) {
      errors.rpassword = "Passwords do not match";
    }
    if (!values.fullname) {
      errors.fullname = "Required";
    }
    if (!values.mobile) {
      errors.mobile = "Required";
    }
    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }
    return errors;
  };

  render() {
    const { accountList } = this.props;
    const { thumb } = this.state;
    return (
      <Box
        background={colors.darkGray}
        gradient={`linear-gradient(135deg, rgba(48,48,49,1), rgba(28,28,29,1))`}
        width="100%"
        shape="rounded"
        elevate
        direction="col"
        alignItems="start"
        paddingVertical={2}
        paddingHorizontal={2}
      >
        <H4 marginBottom={2} light>
          Create User
        </H4>
        <Divider marginBottom={2} color="lightGray" />
        <Formik
          onSubmit={(values) => {
            const { createUser } = this.props;
            createUser({ ...values, countryId: 87 });
          }}
          validate={this.validateForm}
          render={({
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            errors,
            touched,
            isValid,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box marginBottom={2}>
                <DropToUpload
                  onDropDataURI={(dataURIs, files) => {
                    this.handleDropDataURI(dataURIs, files, setFieldValue);
                  }}
                >
                  <Avatar circle big avatar={thumb} />
                </DropToUpload>
              </Box>
              <Box justify="between">
                <Box basis="45%" direction="col">
                  <Input
                    name="username"
                    label="Username"
                    onBlur={handleBlur}
                    placeholder="Enter username..."
                    onChange={handleChange}
                    error={touched.username && errors.username}
                  />
                  <Input
                    name="password"
                    label="Password"
                    onBlur={handleBlur}
                    placeholder="Type password..."
                    onChange={handleChange}
                    error={touched.password && errors.password}
                  />
                  <Input
                    name="rpassword"
                    label="Repeat password"
                    onBlur={handleBlur}
                    placeholder="Retype password..."
                    onChange={handleChange}
                    error={touched.rpassword && errors.rpassword}
                  />
                  <Dropdown
                    label="Account list"
                    name="account"
                    options={accountList}
                    onChange={(field, value) => {
                      setFieldValue(field, value.id);
                    }}
                    placeholder="Select Model..."
                  />
                </Box>
                <Box basis="45%" direction="col">
                  <Input
                    name="fullname"
                    label="Full name"
                    onBlur={handleBlur}
                    placeholder="Enter full name..."
                    onChange={handleChange}
                    error={touched.fullname && errors.fullname}
                  />
                  <Input
                    name="email"
                    label="Email"
                    onBlur={handleBlur}
                    placeholder="Enter email..."
                    onChange={handleChange}
                    error={touched.email && errors.email}
                  />
                  <Input
                    name="mobile"
                    label="Mobile"
                    onBlur={handleBlur}
                    placeholder="Enter mobile..."
                    onChange={handleChange}
                    error={touched.mobile && errors.mobile}
                  />
                </Box>
              </Box>
              <Box justify="end">
                <Button type="submit" disabled={!isValid}>
                  Create user
                </Button>
              </Box>
            </form>
          )}
        />
      </Box>
    );
  }
}

const mapStateToProps = (state, props) => {
  const accountList = [];
  for (let key in state.accounts.byId) {
    if (state.accounts.byId.hasOwnProperty(key)) {
      state.accounts.byId[key].value = state.accounts.byId[key].name;
      accountList.push(state.accounts.byId[key]);
    }
  }
  return {
    accountList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createUser: (values) =>
    dispatch({ type: "USER_CREATE_REQUEST", payload: { values } }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserBox);
