import { combineReducers } from "redux";

const byId = (state = {}, action) => {
  switch (action.type) {
    case "VESSELS_SUCCESS":
    case "CREATE_VESSEL_SUCCESS":
      return {
        ...state,
        ...action.payload.entities.vessels,
      };
    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case "VESSELS_SUCCESS":
      const { result } = action.payload;
      const merged = [...result];
      return [...new Set(merged)];
    case "CREATE_VESSEL_SUCCESS":
      const merged2 = [...state, action.payload.result];
      return [...new Set(merged2)];
    case "VESSEL_DELETE_REQUEST":
      return [...state.filter((id) => id !== action.payload)];
    default:
      return state;
  }
};

const gas = (state = {}, action) => {
  switch (action.type) {
    case "VESSELS_SUCCESS":
    case "CREATE_VESSEL_SUCCESS":
      return {
        ...state,
        ...action.payload.entities.ga,
      };
    default:
      return state;
  }
};

const ga = (state = null, action) => {
  switch (action.type) {
    case "GENERAL_ARRANGEMENT_SUCCESS":
      return action.payload;
    case "CLEAR_GA":
      return null;
    default:
      return state;
  }
};

const images = (state = {}, action) => {
  switch (action.type) {
    case "VESSELS_SUCCESS":
    case "CREATE_VESSEL_SUCCESS":
      return {
        ...state,
        ...action.payload.entities.image,
      };
    default:
      return state;
  }
};

const isFetching = (state = {}, action) => {
  if (action.type === "VESSELS_REQUEST") {
    return {
      ...state,
      allVessels: true,
    };
  } else if (action.type === "GENERAL_ARRANGEMENT_REQUEST") {
    return {
      ...state,
      ga: true,
    };
  } else {
    return {
      ...state,
      allVessels: false,
      ga: false,
    };
  }
};

const search = (state = "", action) => {
  switch (action.type) {
    case "VESSELS_SEARCH":
      return action.payload.value;
    case "RESET_VESSEL_FLOWS":
      return "";
    default:
      return state;
  }
};

const ui = (state = {}, action) => {
  switch (action.type) {
    case "UPDATE_VESSEL":
      return {
        ...state,
        updateVessel: true,
      };
    case "RESET_VESSEL_FLOWS":
      return {
        ...state,
        updateVessel: false,
      };
    default:
      return state;
  }
};

const vessels = combineReducers({
  byId,
  allIds,
  gas,
  ga,
  images,
  isFetching,
  search,
  ui,
});

export default vessels;

export const getVesselById = (state, vesselId) => state.vessels.byId[vesselId];
export const getAllVesselIds = (state) => state.vessels.allIds;
export const getAllVessels = (state) =>
  getAllVesselIds(state).map((id) => getVesselById(state, id));
export const getVesselImage = (state, vesselId) =>
  state.vessels.images[getVesselById(state, vesselId).image]
    ? state.vessels.images[getVesselById(state, vesselId).image].url
    : "";
export const getVesselGA = (state, vesselId) =>
  state.vessels.gas[getVesselById(state, vesselId).ga];
