import { ofType } from "redux-observable";
import { mapTo, delay } from "rxjs/operators";

export const notificationsEpic = action$ =>
  action$.pipe(
    ofType("NOTIFICATION_SUCCESS", "NOTIFICATION"),
    delay(5000),
    mapTo({ type: "NOTIFICATIONS_CLEAR" })
  );

export default notificationsEpic;
