export const successNotification = message => ({
  type: "NOTIFICATION_SUCCESS",
  payload: { message }
});

export const errorNotification = message => ({
  type: "NOTIFICATION_ERROR",
  payload: { message }
});

export const clearNotifications = () => ({
  type: "NOTIFICATIONS_CLEAR"
});
