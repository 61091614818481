import React, { useState } from "react";
import styled from "styled-components";

import { Box, Text, Input, Button, Portal } from "../../components";
import { colors } from "../../components/styled-utils";

const Container = styled.div`
  position: absolute;
  z-index: 1000000000;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(13, 13, 38, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DeleteConfirmation = ({ username, onConfirm }) => {
  const [isOpen, setIsOpen] = useState(null);
  const [value, setValue] = useState("");

  const handleChange = event => setValue(event.target.value);
  const handleConfirm = event => {
    event.preventDefault();
    if (value === username) {
      onConfirm();
      setIsOpen(false);
    }
  };
  return (
    <>
      <Button
        type="button"
        danger
        marginRight={1}
        onClick={() => setIsOpen(true)}
      >
        Delete User
      </Button>
      <Portal>
        {isOpen && (
          <Container onClick={() => setIsOpen(false)}>
            <div onClick={e => e.stopPropagation()}>
              <Box
                paddingVertical={2}
                paddingHorizontal={2}
                background={colors.darkGray}
                shape="rounded"
                elevate
                direction="col"
                alignItems="start"
                maxWidth={320}
              >
                <Text marginBottom={2} weight={500} size="16px">
                  You are about to delete user {username}
                </Text>
                <Text marginBottom={3}>
                  Type user's username below to confirm deletion operation
                </Text>
                <Input
                  name="username"
                  type="text"
                  placeholder="Type user's name to confirm deletion operation"
                  value={value}
                  onChange={handleChange}
                />
                <Button danger rounded onClick={handleConfirm}>
                  Confirm
                </Button>
              </Box>
            </div>
          </Container>
        )}
      </Portal>
    </>
  );
};

DeleteConfirmation.defaultProps = {
  username: "allelos"
};

export default DeleteConfirmation;
