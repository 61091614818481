import React from "react";
import { Box, Divider, H4, utils } from "../../components";

const AccountForm = ({ title, children }) => (
  <Box
    background={utils.colors.darkGray}
    gradient={`linear-gradient(135deg, rgba(48,48,49,1), rgba(28,28,29,1))`}
    width="100%"
    shape="rounded"
    elevate
    direction="col"
    alignItems="start"
    paddingVertical={2}
    paddingHorizontal={2}
    bordered
  >
    <H4 marginBottom={2} light>
      {title}
    </H4>
    <Divider marginBottom={2} color="lightGray" />
    {children}
  </Box>
);

AccountForm.defaultProps = {
  title: "Pane title"
};

export default AccountForm;
