import React, { PureComponent } from "react";
import VirtualList from "react-tiny-virtual-list";
import matchSorter from "match-sorter";
import Downshift from "downshift";
import styled from "styled-components";

import arrowDown from "../../assets/arrow-down.svg";

import { StyledInput, Label } from "../inputs/Input";
import { colors } from "../styled-utils";

const DropdownInput = styled(StyledInput)`
  background-image: url(${arrowDown});
  background-repeat: no-repeat;
  background-size: 16px 8px;
  background-position: calc(100% - 16px) 50%;
  cursor: pointer;
`;

const advancedFilter = (theItems, value) => {
  return value
    ? matchSorter(theItems, value, {
        keys: ["value"],
      })
    : theItems;
};

class Dropdown extends PureComponent {
  static defaultProps = {
    options: [],
  };

  state = {
    isOpen: false,
    inputValue: null,
    selectedItem: null,
    items: [],
  };

  componentDidMount() {
    const { options } = this.props;
    this.setState({
      items: options,
    });
  }

  componentDidUpdate(prevProps) {
    const { options } = this.props;
    if (prevProps.options !== options) {
      this.setState({ items: options });
    }
  }

  handleFocus = (event) => {
    this.setState({ isOpen: true });
    if (this.props.hasOwnProperty("onFocus")) {
      const { onFocus } = this.props;
      onFocus(event);
    }
  };

  handleBlur = (event) => {
    this.setState({ isOpen: false });
    if (this.props.hasOwnProperty("onBlur")) {
      const { onBlur } = this.props;
      onBlur(event);
    }
  };

  handleStateChange = (changes) => {
    const { onChange, name, options } = this.props;

    if (changes.hasOwnProperty("inputValue")) {
      const { inputValue } = changes;
      this.setState({
        inputValue,
        items: advancedFilter(options, inputValue),
      });
    }

    if (changes.hasOwnProperty("selectedItem")) {
      const { selectedItem } = changes;
      this.setState({ isOpen: false, selectedItem, items: options });
      if (onChange) {
        onChange(name, selectedItem);
      }
    }
  };

  itemToString = (item) => (item ? item.value : "");

  render() {
    const { label, name, placeholder, defaultValue } = this.props;
    const { isOpen, inputValue, items } = this.state;
    return (
      <Downshift
        isOpen={isOpen}
        itemCount={items.length}
        itemToString={this.itemToString}
        inputValue={inputValue || defaultValue}
        onStateChange={this.handleStateChange}
      >
        {({
          isOpen,
          getLabelProps,
          getInputProps,
          getItemProps,
          highlightedIndex,
          inputValue,
        }) => (
          <div
            style={{
              marginBottom: 16,
              position: "relative",
              width: "100%",
            }}
          >
            <Label {...getLabelProps()}>{label}</Label>
            <DropdownInput
              {...getInputProps()}
              name={name}
              placeholder={placeholder}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              value={inputValue}
              type="text"
              autoComplete="false"
            />
            {isOpen && (
              <VirtualList
                height="150"
                width="100%"
                itemCount={items.length}
                itemSize={40}
                scrollDirection="vertical"
                style={{
                  zIndex: 5,
                  position: "absolute",
                  backgroundColor: "#333",
                  boxShadow: "0px 10px 6px -6px rgba(0, 0, 0, 0.16)",
                  top: 96,
                  borderRadius: 4,
                }}
                renderItem={({ index, style }) => (
                  <div
                    key={items[index].value}
                    {...getItemProps({
                      item: items[index],
                      index,
                      style: {
                        ...style,
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "transparent",
                        paddingLeft: 16,
                        paddingRight: 16,
                        marginBottom: 8,
                        color:
                          highlightedIndex === index
                            ? colors.lightblue
                            : "rgb(215, 215, 215)",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: 12,
                        transition:
                          "color 0.25s cubic-bezier(0.45, 0.24, 0.66, 0.89)",
                        cursor: "pointer",
                        width: "auto",
                      },
                    })}
                  >
                    {items[index].value}
                  </div>
                )}
              />
            )}
          </div>
        )}
      </Downshift>
    );
  }
}

export default Dropdown;
