import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { faPlus, faRedo } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";

import { Box, Input, Table, ButtonIcon, Modal } from "../../components";
import { colors } from "../../components/styled-utils";

import { getAllUsers } from "../../entities/users/reducer";
import { entitySearch } from "../../entities";

import UpdateUser from "./User";
import CreateUserBox from "./CreateUserBox";

class Users extends PureComponent {
  static defaultProps = {
    users: [],
    isFetching: false,
  };

  state = { selectedUser: null, canCreate: false };

  handleSearch = (event) => {
    event.preventDefault();
    const { search } = this.props;
    search(event.target.value);
  };

  selectRow = (event, userId) => {
    const { updateUser, fetchPermissions } = this.props;
    this.setState({ selectedUser: userId });
    fetchPermissions(userId);
    updateUser();
  };

  refresh = () => {
    const { refresh, reset } = this.props;
    reset();
    refresh();
  };

  onCreate = () => {
    const { createUser } = this.props;
    createUser();
  };

  componentWillUnmount() {
    const { reset } = this.props;
    reset();
  }

  render() {
    const { users, isFetching, showCreatePanel, showUpdatePanel } = this.props;
    const { selectedUser } = this.state;
    return (
      <Box direction="col" width="100%" alignItems="flex-end">
        <Box
          width="100%"
          justify="between"
          alignItems="flex-start"
          marginBottom={1}
        >
          <Box>
            <ButtonIcon onClick={this.onCreate}>
              <FA icon={faPlus} color={colors.smoke} size="xs" />
            </ButtonIcon>
            <ButtonIcon onClick={this.refresh}>
              <FA icon={faRedo} color={colors.smoke} size="xs" />
            </ButtonIcon>
          </Box>
          <Box basis="320px">
            <Input
              type="text"
              name="search"
              onChange={this.handleSearch}
              placeholder="Search..."
            />
          </Box>
        </Box>
        <Table loading={isFetching}>
          <Table.Head>
            <Table.Row>
              {[
                "ID",
                "Username",
                "Full name",
                "Email",
                "Mobile",
                "Last login",
                "Active",
                "Enabled",
                "Orders",
              ].map((item) => (
                <Table.Header key={item}>{item}</Table.Header>
              ))}
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {users.map((user) => {
              const {
                id,
                username,
                fullname,
                email,
                mobile,
                isActive,
                isEnabled,
                order_id,
                lastLogin,
              } = user;

              return (
                <Table.Row
                  key={id}
                  onClick={(event) => this.selectRow(event, id)}
                >
                  <Table.Cell>{id}</Table.Cell>
                  <Table.Cell>{username}</Table.Cell>
                  <Table.Cell>{fullname}</Table.Cell>
                  <Table.Cell>{email}</Table.Cell>
                  <Table.Cell>{mobile}</Table.Cell>
                  <Table.Cell>
                    {lastLogin
                      ? dayjs(lastLogin).format("DD MMM YYYY - h:m a")
                      : "N/A"}
                  </Table.Cell>
                  <Table.Cell>{!!isActive}</Table.Cell>
                  <Table.Cell>{!!isEnabled}</Table.Cell>
                  <Table.Cell>{!!order_id}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        {showCreatePanel && (
          <Modal alignItems="center" isOpen={showCreatePanel}>
            <CreateUserBox />
          </Modal>
        )}
        {showUpdatePanel && (
          <Modal alignItems="center" isOpen={showUpdatePanel} smallMargin>
            <UpdateUser userId={selectedUser} key={selectedUser} />
          </Modal>
        )}
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  const users = entitySearch(state)(
    getAllUsers,
    (state) => state.users.search,
    ["username", "fullname", "email"]
  );

  return {
    users: users(state),
    isFetching: state.users.isFetching.allUsers,
    showCreatePanel: state.ui.users.createUser,
    showUpdatePanel: state.ui.users.updateUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  search: (value) => dispatch({ type: "USERS_SEARCH", payload: { value } }),
  refresh: () => dispatch({ type: "USERS_REQUEST" }),
  createUser: () => dispatch({ type: "CREATE_USER" }),
  updateUser: () => dispatch({ type: "UPDATE_USER" }),
  fetchPermissions: (userId) =>
    dispatch({ type: "USER_PERMISSIONS_REQUEST", payload: { userId: userId } }),
  reset: () => dispatch({ type: "RESET_USER_FLOWS" }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
