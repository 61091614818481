import { connect } from "react-redux";
import * as vesselActions from "../../../entities/vessels/actions";

import CreateVessel from "../components/CreateVessel";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  createVessel: (values) => dispatch(vesselActions.createVesselRequest(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateVessel);
