import { combineReducers } from "redux";

const byId = (state = {}, action) => {
  switch (action.type) {
    case "NOTES_SUCCESS":
      return {
        ...action.payload.entities.note
      };
    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case "NOTES_SUCCESS":
      return action.payload.result;
    default:
      return state;
  }
};

const isFetching = (state = false, action) => {
  if (action.type === "NOTES_REQUEST") {
    return true;
  } else {
    return false;
  }
};

const selectedNote = (state = null, action) => {
  if (action.type === "SELECT_NOTE") {
    return action.payload;
  } else {
    return null;
  }
};

const notes = combineReducers({
  byId,
  allIds,
  isFetching,
  selectedNote
});

export default notes;

export const getNoteById = (state, noteId) => state.notes.byId[noteId];
export const getAllNoteIds = state => state.notes.allIds;
export const getAllNotes = state =>
  getAllNoteIds(state).map(id => getNoteById(state, id));
