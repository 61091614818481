import { combineReducers } from "redux";

const byId = (state = {}, action) => {
  switch (action.type) {
    case "CURRENT_USER_SUCCESS":
      return {
        ...state,
        ...action.payload.entities.users
      };
    case "USERS_RECEIVE":
      return {
        ...state,
        ...action.payload.entities.users
      };
    case "USER_UPDATE_SUCCESS":
      return {
        ...state,
        [action.payload.result]:
          action.payload.entities.users[action.payload.result]
      };
    case "USER_CREATE_SUCCESS":
      return {
        ...state,
        ...action.payload.entities.users
      };
    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case "USERS_RECEIVE":
      const { result } = action.payload;
      const merged = [...result];
      return [...new Set(merged)];
    case "CURRENT_USER_SUCCESS":
    case "USER_CREATE_SUCCESS":
      return [...new Set([...state, action.payload.result])];
    case "USER_DELETE_SUCCESS":
      return [...state.filter(id => id !== action.payload.userId)];
    default:
      return state;
  }
};

const currentUser = (state = null, action) => {
  switch (action.type) {
    case "CURRENT_USER_SUCCESS":
      return action.payload.result;
    default:
      return state;
  }
};

const isFetching = (state = {}, action) => {
  if (
    action.type === "USERS_REQUEST" ||
    action.type === "CURRENT_USER_REQUEST"
  ) {
    return {
      ...state,
      allUsers: true
    };
  } else {
    return {
      ...state,
      allUsers: false
    };
  }
};

const search = (state = "", action) => {
  switch (action.type) {
    case "USERS_SEARCH":
      return action.payload.value;
    case "RESET_USER_FLOWS":
      return "";
    default:
      return state;
  }
};

const users = combineReducers({
  byId,
  allIds,
  currentUser,
  search,
  isFetching
});

export default users;

export const getUsersById = state => state.users.byId;
export const getUsersIds = state => state.users.allIds;
export const getUserById = (state, userId) => state.users.byId[userId];
export const getAllUsers = state =>
  getUsersIds(state).map(id => getUserById(state, id));

export const getCurrentUser = state =>
  state.users.byId[state.users.currentUser];
