import React from "react";

import { Box, utils } from "../../../components";

const Note = ({ note, hasNote }) => {
  if (hasNote) {
    const { snapshotUrl, imagePosition } = note;
    const point = JSON.parse(imagePosition);
    return (
      <Box>
        <Box height={640} overflow="hidden" shape="rounded">
          <span
            style={{
              position: "absolute",
              top: point.y,
              left: point.x,
              width: 24,
              height: 24,
              background: utils.colors.lightblue,
              border: `2px solid ${utils.colors.white}`,
              borderRadius: "50%",
              boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.75)"
            }}
          />
          <img
            style={{ height: "auto", width: "100%" }}
            src={snapshotUrl}
            alt="snapshot"
          />
        </Box>
      </Box>
    );
  }
  return null;
};

export default Note;
