import styled from "styled-components";

import * as utils from "../styled-utils";
import * as helpers from "./helpers";

// Box

const Box = styled.div`
  display: ${props => props.display || "flex"};
  position: ${props => helpers.getPosition(props.position)};

  flex: ${props => helpers.getBehaviour(props.flex)};
  flex-basis: ${props => props.basis || "auto"};
  flex-direction: ${props =>
    helpers.getDirection(props.direction || props.flexDirection)};
  flex-wrap: ${props => (props.wrap ? "wrap" : "nowrap")};
  justify-content: ${props =>
    helpers.justify(props.justifyContent || props.justify)};
  align-items: ${props => props.alignItems || "center"};

  background: ${props => props.background || "transparent"};
  background-image: ${props =>
    props.gradient
      ? props.gradient
      : props.imageUrl
      ? `url(${props.imageUrl})`
      : "none"};

  border-radius: ${props => helpers.getShape(props.shape)};
  border: ${props => props.bordered && `1px solid ${utils.colors.lightGray}`};

  height: ${props => utils.getPixels(props.height)};
  width: ${props => utils.getPixels(props.width)};
  max-height: ${props => utils.getPixels(props.maxHeight)};
  max-width: ${props => utils.getPixels(props.maxWidth)};
  min-height: ${props => utils.getPixels(props.minHeight)};
  min-width: ${props => utils.getPixels(props.minWidth)};

  box-shadow: ${props => props.elevate && "0 1px 46px -4px rgba(0,0,0,.28)"};
  overflow: ${props => props.overflow && props.overflow};
  z-index: ${props => props.z && props.z};
  ${utils.getMargins};
  ${utils.getPaddings};
  ${utils.getAbsolutePositioning};
`;

export default Box;
