import React from "react";
import { Formik } from "formik";
import DropToUpload from "react-drop-to-upload";

import {
  Tabs,
  Box,
  Input,
  Avatar,
  Button,
  Dropdown,
} from "../../../components";

import VesselViews from "../containers/VesselViews";
import VesselView from "../containers/VesselView";
import GeneralArrangement from "../containers/GeneralArrangement";

import Note from "../containers/Note";
import { updateVessel } from "../../../api/vessels";
import { store } from "react-notifications-component";

const labels = [
  { label: "Edit Vessel" },
  { label: "Vessel Views" },
  { label: "General Arrangement" },
];

const Vessel = ({
  vessel,
  avatar,
  editView,
  token,
  accountList,
  vesselTypesList,
  flagsList,
  deleteVessel,
}) => {
  const handleVesselSave = (values) => {
    updateVessel(vessel.id, values, token).then((res) => {
      if (res.status === 200) {
        store.addNotification({
          title: "Success",
          message: "You successfully updated vessel",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeInRight"],
          animationOut: ["animate__animated", "animate__fadeOutRight"],
          dismiss: {
            duration: 5000,
            delay: 1000,
            showIcon: true,
          },
          slidingEnter: {
            duration: 2000,
          },
        });
      }
    });
  };
  const handleDeleteVessel = () => {
    deleteVessel(vessel.id);
  };

  return (
    <Tabs labels={labels}>
      <Box width="100%">
        <Formik
          initialValues={{
            companyName: vessel.companyName || "",
            imo: vessel.imo,
            mmsi: vessel.mmsi,
            name: vessel.name,
            dwt: vessel.dwt || "",
            length: vessel.length,
            width: vessel.width,
            tonage: vessel.tonage || "",
            avatar: avatar || "",
            accountId: vessel.accountAlt.id,
            typeId: vessel.typeId,
            flagId: vessel.flagId,
            year: vessel.year,
            enabled: vessel.enabled,
          }}
          onSubmit={handleVesselSave}
          render={({ values, handleChange, handleSubmit, setFieldValue }) => (
            <form>
              <Box marginBottom={2}>
                <DropToUpload
                  onDropDataURI={(dataURIs, files) => {
                    setFieldValue("avatar", files[0]);
                  }}
                >
                  <Avatar circle big avatar={values.avatar || ""} />
                </DropToUpload>
              </Box>
              <Box width="100%" justify="between" alignItems="start">
                <Box direction="col" basis="33%">
                  <Input
                    name="companyName"
                    label="Company Name"
                    type="text"
                    value={values.companyName}
                    onChange={handleChange}
                  />
                  <Input
                    name="imo"
                    label="IMO"
                    type="text"
                    value={values.imo}
                    onChange={handleChange}
                  />
                  <Input
                    name="mmsi"
                    label="MMSI"
                    type="text"
                    value={values.mmsi}
                    onChange={handleChange}
                  />
                  <Input
                    name="name"
                    label="Name"
                    type="text"
                    value={values.name}
                    onChange={handleChange}
                  />
                </Box>
                <Box direction="col" basis="33%">
                  <Input
                    name="dwt"
                    label="Dead weight (t)"
                    type="text"
                    value={values.dwt}
                    onChange={handleChange}
                  />
                  <Input
                    name="length"
                    label="Length (m)"
                    type="text"
                    value={values.length}
                    onChange={handleChange}
                  />
                  <Input
                    name="width"
                    label="Width (m)"
                    type="text"
                    value={values.width}
                    onChange={handleChange}
                  />
                  <Input
                    name="tonage"
                    label="Tonage (t)"
                    type="text"
                    value={values.tonage}
                    onChange={handleChange}
                  />
                </Box>
                <Box basis="33%" direction="col">
                  <Dropdown
                    label="Account list"
                    name="accountId"
                    defaultValue={vessel.accountAlt.name}
                    options={accountList}
                    onChange={(field, value) => {
                      setFieldValue(field, value.id);
                    }}
                    placeholder="Select Model..."
                  />
                  <Dropdown
                    label="Vessel Types list"
                    name="typeId"
                    options={vesselTypesList}
                    defaultValue={
                      vesselTypesList.find((el) => el.id === vessel.typeId)
                        .description
                    }
                    onChange={(field, value) => {
                      setFieldValue(field, value.id);
                    }}
                    placeholder="Select Model..."
                  />
                  <Dropdown
                    label="Vessel Flags list"
                    name="flagId"
                    options={flagsList}
                    defaultValue={
                      flagsList.find((el) => el.id === vessel.flagId)
                        .description
                    }
                    onChange={(field, value) => {
                      setFieldValue(field, value.id);
                    }}
                    placeholder="Select Model..."
                  />
                </Box>
              </Box>
              <Box justify="end">
                <Button danger marginRight={1} onClick={handleDeleteVessel}>
                  Delete Vessel
                </Button>
                <Button type="submit" onClick={handleSubmit}>
                  Save
                </Button>
              </Box>
            </form>
          )}
        />
      </Box>
      <Box width="100%" direction="col">
        <VesselViews vesselId={vessel.id} />
        {editView && <VesselView />}
        <Note />
      </Box>
      <Box width="100%" direction="col">
        <GeneralArrangement vesselId={vessel.id} />
      </Box>
    </Tabs>
  );
};

export default Vessel;
