import { ofType, combineEpics } from "redux-observable";
import * as vesselTypesActions from "./actions";
import { normalize, schema } from "normalizr";
import { ajax } from "rxjs/ajax";
import { map, switchMap, catchError } from "rxjs/operators";
import { URL } from "../../global/constants";
import { getToken } from "../auth/reducer";

const type = new schema.Entity("type");

const getVesselTypes = (action$, state$) =>
  action$.pipe(
    ofType("VESSEL_TYPES_REQUEST"),
    map(() => getToken(state$.value)),
    switchMap((token) =>
      ajax({
        url: `${URL}/secured/vesseltypes`,
        headers: { Authorization: token },
      }).pipe(
        map((res) => {
          return vesselTypesActions.vesselTypesSuccess(
            normalize(res.response, [type])
          );
        }),
        catchError((e) =>
          Promise.resolve(vesselTypesActions.vesselTypesError(e))
        )
      )
    )
  );

export default combineEpics(getVesselTypes);
