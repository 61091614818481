import { combineReducers } from "redux";

const vesselTypesReq = (state = {}, action) => {
  switch (action.type) {
    case "VESSEL_TYPES_REQUEST":
      return {
        ...state,
      };
    default:
      return state;
  }
};

const allVesselTypes = (state = [], action) => {
  switch (action.type) {
    case "VESSEL_TYPES_SUCCESS":
      const { result } = action.payload;
      const allTypes = [...result];
      return allTypes;
    default:
      return state;
  }
};

const vesselTypes = combineReducers({
  vesselTypesReq,
  allVesselTypes,
});

export default vesselTypes;

export const getAllVesselTypes = (state) => state.vesselTypes.alltypes;
