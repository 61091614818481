export const vesselsRequest = () => ({ type: "VESSELS_REQUEST" });
export const vesselsSuccess = ({ entities, result }) => ({
  type: "VESSELS_SUCCESS",
  payload: { entities, result },
});

export const vesselsViewSuccess = ({ entities, result }) => ({
  type: "VESSEL_VIEW_SUCCESS",
  payload: { entities, result },
});
export const vesselsError = (error) => ({
  type: "VESSELS_ERROR",
  payload: { ...error },
});

export const searchVessels = (value) => ({
  type: "VESSELS_SEARCH",
  payload: { value },
});

export const createVesselRequest = (values) => ({
  type: "CREATE_VESSEL_REQUEST",
  payload: { ...values },
});
export const createVesselSuccess = ({ entities, result }) => ({
  type: "CREATE_VESSEL_SUCCESS",
  payload: { entities, result },
});
export const createVesselError = (error) => ({
  type: "CREATE_VESSEL_ERROR",
  payload: { ...error },
});

export const createVesselViewRequest = (values) => ({
  type: "UPDATE_VESSEL_VIEW_REQUEST",
  payload: { ...values },
});

export const createVesselViewSuccess = ({ entities, result }) => ({
  type: "CREATE_VESSEL_VIEW_SUCCESS",
  payload: { entities, result },
});

export const createVesselViewError = (values) => ({
  type: "CREATE_VESSEL_VIEW_ERROR",
  payload: { ...values },
});

// delete Vessel action start

export const requestVesselDelete = (id) => ({
  type: "VESSEL_DELETE_REQUEST",
  payload: id,
});

export const successVesselDelete = ({ entities, result }) => ({
  type: "VESSEL_DELETE_SUCCESS",
  payload: { entities, result },
});

export const errorDeleteVessel = (error) => ({
  type: "VESSEL_DELETE_ERROR",
  payload: error,
});

//delete Vessel action end

// delete Vessel view action start

export const requestVesselViewDelete = (id) => ({
  type: "VESSEL_VIEW_DELETE_REQUEST",
  payload: id,
});

export const successVesselViewDelete = ({ entities, result }) => ({
  type: "VESSEL_VIEW_DELETE_SUCCESS",
  payload: { entities, result },
});

export const errorDeleteVesselView = (error) => ({
  type: "VESSEL_VIEW_DELETE_ERROR",
  payload: error,
});

//delete Vessel view action end
