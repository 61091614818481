import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { createSelector } from "reselect";

import { Box, Input, Button, Dropdown2 as Dropdown } from "../../components";

import { getAllUsers } from "../../entities/users/reducer";

import AccountForm from "./AccountForm";

const CreateAccount = ({ users, createAccount }) => {
  return (
    <AccountForm title="Create Account">
      <Formik
        onSubmit={values => {
          createAccount({
            ...values,
            ownerId: values.owner.id,
            countryId: 87
          });
        }}
        render={({ handleChange, handleSubmit, values, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Box justify="between">
              <Box flex="grow" direction="col">
                <Input
                  name="name"
                  label="Account name"
                  placeholder="Enter account name..."
                  onChange={handleChange}
                  value={values.name}
                />
                <Input
                  name="description"
                  label="Description"
                  placeholder="Enter a description..."
                  onChange={handleChange}
                  value={values.description}
                />
                <Dropdown
                  name="owner"
                  label="Owner"
                  placeholder="Select owner..."
                  onChange={setFieldValue}
                  options={users}
                />
              </Box>
            </Box>
            <Box justify="end">
              <Button type="submit">Create account</Button>
            </Box>
          </form>
        )}
      />
    </AccountForm>
  );
};

const mapStateToProps = state => {
  const users = createSelector(
    [getAllUsers],
    users =>
      users.reduce((acc, user) => {
        return [...acc, { value: user.username, id: user.id }];
      }, [])
  );
  return {
    users: users(state)
  };
};

const mapDispatchToProps = dispatch => ({
  createAccount: values =>
    dispatch({ type: "CREATE_ACCOUNT_REQUEST", payload: { values } }),
  getCountries: () => dispatch({ type: "COUNTRIES_REQUEST" })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAccount);
