import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

import { Box, Text, Divider, utils, Button } from "../../../components";

const Container = styled.div`
  width: 100%;
  height: 100%;

  & > svg {
    height: 100%;
    width: 100%;
  }

  & > svg .onHover {
    fill: ${utils.colors.offWhite};
  }
`;

const GeneralArrangement = ({
  ga,
  gaFilename,
  uploadGa,
  fetchGa,
  clearGa,
  loading
}) => {
  const [filename, setFilename] = useState(null);
  const fileUploader = useRef(null);

  useEffect(() => {
    fetchGa();
    return () => {
      clearGa();
    };
  }, []);

  const handleClick = () => {
    fileUploader.current.click();
  };

  const handleChange = event => {
    if (
      event.target.files.length !== 0
      // event.target.files[0].type === "image/svg+xml"
    ) {
      setFilename(event.target.files[0].name);
      uploadGa(event.target.files[0]);
    }
  };

  const createMarkup = () => {
    return { __html: ga };
  };

  return (
    <Box direction="col" width="100%">
      <Text weight="700" marginBottom={4}>
        {gaFilename}
      </Text>
      {!loading ? (
        <Container dangerouslySetInnerHTML={createMarkup()} />
      ) : (
        <Text weight="400" size="12px">
          loading...
        </Text>
      )}
      <Divider color="lightGray" marginTop={4} marginBottom={2} />
      <Box align="center" width="100%" justify="end">
        {false && (
          <Text weight="700" marginRight={3}>
            {filename}
          </Text>
        )}
        <Button onClick={handleClick}>Select GA to upload</Button>
        <input
          type="file"
          style={{ display: "none" }}
          ref={fileUploader}
          onChange={handleChange}
        />
      </Box>
    </Box>
  );
};
export default GeneralArrangement;
