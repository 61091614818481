import React, { PureComponent } from "react";
import styled from "styled-components";

import { omit } from "../helpers";
import { colors } from "../styled-utils";

const CheckboxContainer = styled.div`
  position: relative;
  line-height: 1.9;
`;

const CheckboxInput = styled.input.attrs(props => ({
  type: "checkbox",
  id: props.name ? props.name : null,
  name: props.name ? props.name : null
}))`
  display: none;
  opacity: 0;
  transition: all 125ms ease;

  + label::after {
    content: none;
  }

  + label::before {
    border-radius: 4px;
  }

  &:checked + label::after {
    content: "";
  }

  &:checked + label::before {
    content: "";
    background-image: linear-gradient(135deg, #1d90cb, #0e86c4);
    border: 1px solid ${colors.smoke};
  }

  &:focus + label::before {
    border: 1px solid ${colors.ghost};
  }
`;

const CheckboxLabel = styled.label.attrs(props => ({
  htmlFor: props.name ? props.name : null
}))`
  display: inline-block;
  padding-left: 40px;
  position: relative;
  user-select: none;
  outline: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: ${colors.smoke};
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    position: absolute;
    display: inline-block;
    margin-top: 0;
  }

  &::before {
    /* top: 4px; */
    left: 0;
    width: 25px;
    height: 25px;
    background: #383838;
    border: 1px solid ${colors.lightGray};
    transition: all 50ms ease;
  }

  &::after {
    top: 8px;
    left: 6px;
    height: 6px;
    width: 12px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(-45deg);
  }
`;

class Checkbox extends PureComponent {
  static defaultProps = {
    name: "checkbox",
    label: "checkbox",
    onChange: () => {}
  };

  render() {
    const { name, label } = this.props;
    return (
      <CheckboxContainer>
        <CheckboxInput {...omit(["label"], this.props)} />
        <CheckboxLabel name={name}>{label}</CheckboxLabel>
      </CheckboxContainer>
    );
  }
}

export default Checkbox;
