import { combineReducers } from "redux";

const byId = (state = {}, action) => {
  switch (action.type) {
    case "ACCOUNTS_SUCCESS":
      return action.payload.entities.accounts;
    case "CREATE_ACCOUNT_SUCCESS":
      return {
        ...state,
        ...action.payload.entities.accounts
      };
    default:
      return state;
  }
};

const allIds = (state = [], action) => {
  switch (action.type) {
    case "ACCOUNTS_SUCCESS":
      const { result } = action.payload;
      const merged = [...result];
      return [...new Set(merged)];
    case "CREATE_ACCOUNT_SUCCESS":
      return [...new Set([...state, action.payload.result])];
    case "ACCOUNT_DELETE_REQUEST":
      return [...state.filter(id => id !== action.payload)];
    default:
      return state;
  }
};

const isFetching = (state = {}, action) => {
  if (action.type === "ACCOUNTS_REQUEST") {
    return {
      ...state,
      allAccounts: true
    };
  } else {
    return {
      ...state,
      allAccounts: false
    };
  }
};

const search = (state = "", action) => {
  switch (action.type) {
    case "ACCOUNTS_SEARCH":
      return action.payload.value;
    case "RESET_ACCOUNT_FLOWS":
      return "";
    default:
      return state;
  }
};

const accounts = combineReducers({
  byId,
  allIds,
  isFetching,
  search
});

export default accounts;

export const getAccountById = (state, accountId) =>
  state.accounts.byId[accountId];
export const getAllAccountIds = state => state.accounts.allIds;
export const getAllAccounts = state =>
  getAllAccountIds(state).map(id => getAccountById(state, id));
