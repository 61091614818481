import React, { PureComponent } from "react";
import { connect } from "react-redux";
import animejs from "animejs";

import { Box, Input, Button } from "../../components";
import { isLoading, getToken } from "../../entities/auth/reducer";

class Login extends PureComponent {
  boxRef = React.createRef();

  state = {};

  componentDidMount() {
    const { onLogin, history } = this.props;
    onLogin(history);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.error !== this.props.error) {
      animejs({
        targets: this.boxRef.current,
        keyframes: [
          { translateX: -4 },
          { translateX: 4 },
          { translateX: -8 },
          { translateX: 8 },
          { translateX: -16 },
          { translateX: 16 },
          { translateX: -8 },
          { translateX: 8 },
          { translateX: -4 },
          { translateX: 4 },
          { translateX: 0 }
        ],
        duration: 800,
        // direction: "alternate",
        easing: "easeInOutQuad"
      });
    }
  }

  onChange = event => {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  onSubmit = event => {
    event.preventDefault();
    const { username, password } = this.state;
    const { history, login } = this.props;
    login(username, password, history);
  };

  render() {
    const { isLoading } = this.props;
    return (
      <Box
        background="rgb(28,28,29)"
        imageUrl="https://images.unsplash.com/photo-1449028288989-df05d40bcbb4?ixlib=rb-1.2.1&auto=format&fit=crop&w=1952&q=80"
        width="100vw"
        height="100vh"
        justify="center"
        flexDirection="col"
      >
        <Box
          style={{ transform: "translateX(0)" }}
          ref={this.boxRef}
          gradient={`linear-gradient(
    135deg,
    rgba(48,48,49,0.75),
    rgba(28,28,29,0.75)
  )`}
          shape="rounded"
          flexDirection="col"
          paddingVertical={3}
          paddingHorizontal={2}
          elevate
          marginBottom={2}
        >
          <form onSubmit={this.onSubmit}>
            <Box flexDirection="col">
              <Input
                label="Username"
                type="text"
                name="username"
                onChange={this.onChange}
              />
              <Input
                label="Password"
                type="password"
                name="password"
                onChange={this.onChange}
              />
            </Box>
            <Box justify="center" width="100%">
              <Button type="submit" disabled={isLoading} fullWidth>
                {isLoading ? "LOGGING IN..." : "LOGIN"}
              </Button>
            </Box>
          </form>
        </Box>
        <span style={{ fontSize: 11, color: "rgba(205,205,205,1)" }}>
          powered by Vesselfront™
        </span>
      </Box>
    );
  }
}

const mapStateToProps = state => ({
  token: getToken(state),
  isLoading: isLoading(state),
  error: state.errors && state.errors.LOGIN
});

const mapDispatchToProps = dispatch => ({
  login: (username, password, history) =>
    dispatch({
      type: "LOGIN_REQUEST",
      payload: { username, password, history }
    }),
  onLogin: history => dispatch({ type: "ON_LOGIN", payload: { history } })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
