import React, { useState } from "react";
import PropTypes from "prop-types";

import { Box, Text, Divider, utils } from "../index";

const Tabs = ({ children, labels, margin }) => {
  const [activeIndex, selectTab] = useState(0);

  const selectTabIndex = tabIndex => selectTab(tabIndex);

  return (
    <Box
      width="100%"
      direction="col"
      alignItems="start"
      paddingVertical={2}
      paddingHorizontal={2}
      marginTop={margin ? margin.marginTop : 0}
      shape="rounded"
      elevate
      gradient={`linear-gradient(135deg, ${utils.colors.darkGray}, ${
        utils.colors.black
      })`}
    >
      <Box basis="100%" marginBottom={2}>
        {labels.map((tab, index) => (
          <Text
            size={14}
            weight={400}
            marginRight={2}
            color={activeIndex === index ? "lightblue" : "smoke"}
            key={tab.label}
            onClick={() => selectTabIndex(index)}
          >
            {tab.label}
          </Text>
        ))}
      </Box>
      <Divider color="lightGray" marginBottom={2} />
      <Box alignItems="flex-start" width="100%">
        {children.length > 0 ? children[activeIndex] : children}
      </Box>
    </Box>
  );
};

Tabs.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  labels: PropTypes.array.isRequired
};

export default Tabs;
