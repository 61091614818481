import styled from "styled-components";
import * as utils from "../styled-utils";

const ButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  border: 2px solid ${utils.colors.lightGray};
  background: ${utils.colors.darkGray};
  background-image: linear-gradient(
    135deg,
    ${utils.colors.darkGray},
    ${utils.colors.black}
  );
  margin-right: 8px;
  cursor: pointer;
  transition: all 125ms ease;

  &:hover {
    box-shadow: 0 1px 46px -4px rgba(0, 0, 0, 0.28);
    background-image: linear-gradient(
      135deg,
      ${utils.colors.lightGray},
      ${utils.colors.darkGray}
    );
  }
`;

export default ButtonIcon;
