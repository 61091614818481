import { ajax } from "rxjs/ajax";
import {
  map,
  switchMap,
  pluck,
  catchError,
  filter,
  merge
} from "rxjs/operators";
import { ofType, combineEpics } from "redux-observable";
import { normalize, schema } from "normalizr";

import * as accountActions from "./actions";
import * as notificationActions from "../notifications/actions";

import { getToken } from "../auth/reducer";
import { createFormData } from "../utilities";
import { URL } from "../../global/constants";

const country = new schema.Entity("country");
const account = new schema.Entity("accounts", {
  country: country
});

const getAccounts = (action$, state$) =>
  action$.pipe(
    ofType("ACCOUNTS_REQUEST"),
    map(() => state$.value),
    map(getToken),
    switchMap(token =>
      ajax({
        url: `${URL}/secured/accounts?admin=1`,
        headers: {
          Authorization: token
        }
      }).pipe(
        pluck("response"),
        map(response =>
          accountActions.accountsSuccess(normalize(response, [account]))
        ),
        catchError(e =>
          Promise.resolve(
            accountActions.accountsError({
              message: e.message,
              status: e.status
            })
          )
        )
      )
    )
  );

const createAccount = (action$, state$) =>
  action$.pipe(
    ofType("CREATE_ACCOUNT_REQUEST"),
    map(({ payload }) => ({
      token: getToken(state$.value),
      ...payload
    })),
    switchMap(({ token, values }) =>
      ajax({
        method: "POST",
        headers: {
          Authorization: token
        },
        body: createFormData(values),
        url: `${URL}/secured/accounts`
      }).pipe(
        pluck("response"),
        map(response =>
          accountActions.successCreateAccount(normalize(response, account))
        ),
        catchError(e =>
          Promise.resolve(
            accountActions.errorCreateAccount({
              message: e.message,
              status: e.status
            })
          )
        )
      )
    )
  );

const deleteAccount = (action$, state$) =>
  action$.pipe(
    ofType("ACCOUNT_DELETE_REQUEST"),
    map(({ payload }) => ({
      token: getToken(state$.value),
      id: payload
    })),
    switchMap(({ token, id }) =>
      ajax({
        method: "DELETE",
        headers: {
          Authorization: token
        },
        url: `${URL}/secured/accounts/${id}`
      }).pipe(
        pluck("response"),
        filter(response => response === "OK"),
        map(() => ({ type: "ACCOUNT_DELETE_SUCCESS", payload: id })),
        catchError(e =>
          Promise.resolve(
            accountActions.errorDeleteAccount({
              message: e.message,
              status: e.status
            })
          )
        )
      )
    )
  );

const accountNotifications = action$ => {
  const createAccount = action$.pipe(
    ofType("CREATE_ACCOUNT_SUCCESS"),
    map(({ payload }) =>
      notificationActions.successNotification(
        `Account ${
          payload.entities.accounts[payload.result].name
        } created successfuly!`
      )
    )
  );

  const deleteAccount = action$.pipe(
    ofType("ACCOUNT_DELETE_SUCCESS"),
    map(({ payload }) =>
      notificationActions.successNotification(
        `Account ${payload} deleted successfuly!`
      )
    )
  );

  const testAccount = action$.pipe(
    ofType("ACCOUNT_TEST"),
    map(() => ({ type: "BOOM_3" }))
  );

  return createAccount.pipe(merge(deleteAccount, testAccount));
};

export default combineEpics(
  getAccounts,
  createAccount,
  deleteAccount,
  accountNotifications
);
