import styled from "styled-components";
import { getMargins } from "../styled-utils";

const Button = styled.button`
  padding: 12px 24px;
  border-radius: 4px;
  width: ${props => (props.fullWidth ? "100%" : "auto")};

  background-image: ${props =>
    props.danger
      ? "linear-gradient(135deg, #c33347,#b42323)"
      : "linear-gradient(135deg, #1d90cb, #0e86c4)"};
  border: 1px solid ${props => (props.danger ? "#b42324" : "#137dba")};

  text-align: center;
  vertical-align: middle;

  box-shadow: none;
  color: white;
  cursor: pointer;

  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;

  ${getMargins};
  &:hover {
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    opacity: 0.75;
    cursor: not-allowed;
  }
`;

export default Button;
