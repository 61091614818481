import { ofType, combineEpics } from "redux-observable";
import { ajax } from "rxjs/ajax";
import { map, switchMap, catchError } from "rxjs/operators";
import { normalize, schema } from "normalizr";

import { URL } from "../../global/constants";

import { getToken } from "../auth/reducer";

const note = new schema.Entity("note");
const notes = new schema.Array(note);

const getNotesEpic = (action$, state$) =>
  action$.pipe(
    ofType("NOTES_REQUEST"),
    map(({ payload }) => ({
      id: payload,
      token: getToken(state$.value)
    })),
    switchMap(({ id, token }) =>
      ajax({
        url: `${URL}/secured/vesselView/${id}/notes`,
        headers: { Authorization: token }
      }).pipe(
        map(res => ({
          type: "NOTES_SUCCESS",
          payload: normalize(res.response, notes)
        })),
        catchError(e => Promise.resolve({ type: "NOTES_ERROR" }))
      )
    )
  );

const monitorViewIdEpic = (action$, state$) =>
  action$.pipe(
    ofType("SELECT_VIEW"),
    map(action => ({ type: "NOTES_REQUEST", payload: action.payload }))
  );

export default combineEpics(getNotesEpic, monitorViewIdEpic);
