export const requestUsers = () => ({ type: "USERS_REQUEST " });
export const receiveUsers = ({ entities, result }) => ({
  type: "USERS_RECEIVE",
  payload: { entities, result }
});
export const errorUsers = error => ({
  type: "USERS_ERROR",
  payload: {
    status: error.response.status,
    code: error.response.data.code,
    message: error.response.data.message
  }
});

export const requestCurrentUser = () => ({ type: "CURRENT_USER_REQUEST" });
export const successCurrentUser = ({ entities, result }) => ({
  type: "CURRENT_USER_SUCCESS",
  payload: { entities, result }
});
export const errorCurrentUser = error => ({
  type: "CURRENT_USER_ERROR",
  payload: {
    ...error
  }
});

export const requestUpdateUser = values => ({
  type: "USER_UPDATE_REQUEST",
  payload: { values }
});
export const successUpdateUser = ({ entities, result }) => ({
  type: "USER_UPDATE_SUCCESS",
  payload: { entities, result }
});
export const errorUpdateUser = error => ({
  type: "USER_UPDATE_ERROR",
  payload: {
    ...error
  }
});

export const requestCreateUser = values => ({
  type: "USER_CREATE_REQUEST",
  payload: { values }
});
export const successCreateUser = ({ entities, result }) => ({
  type: "USER_CREATE_SUCCESS",
  payload: { entities, result }
});
export const successCreateUserCloseModal = ({ entities, result }) => ({
  type: "CLOSE_MODAL",
  payload: { entities, result }
});
export const errorCreateUser = error => ({
  type: "USER_CREATE_ERROR",
  payload: {
    ...error
  }
});

export const requestDeleteUser = userId => ({
  type: "USER_DELETE_REQUEST",
  payload: { userId }
});
export const successDeleteUser = userId => ({
  type: "USER_DELETE_SUCCESS",
  payload: { userId }
});
export const errorDeleteUser = error => ({
  type: "USER_DELETE_ERROR",
  payload: {
    ...error
  }
});

export const searchUsers = value => ({
  type: "USERS_SEARCH",
  payload: { value }
});
