import React from "react";

import { Box, H1, H2 } from "../../components";
import { colors } from "../../components/styled-utils";

const NotFound = () => (
  <Box
    minWidth="100vw"
    minHeight="100vh"
    background={colors.black}
    direction="col"
    justify="center"
  >
    <H1 bold>404</H1>
    <H2>Page not found</H2>
  </Box>
);

export default NotFound;
