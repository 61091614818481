import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import { Box, Input, Button, Avatar, Divider, H4 } from "../../../components";
import { colors } from "../../../components/styled-utils";
// import { getUserById } from "../../../entities/users/reducer";
import DropToUpload from "react-drop-to-upload";

class CreateVesselViewBox extends PureComponent {
  state = { thumb: null };

  handleDropDataURI = (dataURIs, files, setFieldValue) => {
    this.setState({ thumb: dataURIs[0] });
    setFieldValue("thumbnail", files[0]);
  };

  validateForm = (values) => {
    let errors = {};
    if (!values.matterportId) {
      errors.matterportId = "Required";
    }
    if (!values.title) {
      errors.title = "Required";
    }
    if (!values.description) {
      errors.description = "Required";
    }
    if (!values.visits) {
      errors.visits = "Required";
    }
    if (!values.snapshots) {
      errors.snapshots = "Required";
    }
    if (!values.area) {
      errors.area = "Required";
    }
    if (!values.notes) {
      errors.notes = "Required";
    }
    return errors;
  };

  render() {
    const { vesselId } = this.props;
    const { thumb } = this.state;
    return (
      <Box
        background={colors.darkGray}
        gradient={`linear-gradient(135deg, rgba(48,48,49,1), rgba(28,28,29,1))`}
        width="100%"
        shape="rounded"
        elevate
        direction="col"
        alignItems="start"
        paddingVertical={2}
        paddingHorizontal={2}
      >
        <H4 marginBottom={2} light>
          Create Vessel View
        </H4>
        <Divider marginBottom={2} color="lightGray" />
        <Formik
          onSubmit={(values) => {
            const { createUser } = this.props;
            createUser({ ...values, vesselId: vesselId });
          }}
          validate={this.validateForm}
          render={({
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            errors,
            touched,
            isValid,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box marginBottom={2}>
                <DropToUpload
                  onDropDataURI={(dataURIs, files) => {
                    this.handleDropDataURI(dataURIs, files, setFieldValue);
                  }}
                >
                  <Avatar circle big avatar={thumb} />
                </DropToUpload>
              </Box>
              <Box justify="between">
                <Box basis="45%" direction="col">
                  <Input
                    name="matterportId"
                    label="Matterport ID"
                    onBlur={handleBlur}
                    placeholder="Enter Matterport ID..."
                    onChange={handleChange}
                    error={touched.matterportId && errors.matterportId}
                  />
                  <Input
                    name="title"
                    label="Title"
                    onBlur={handleBlur}
                    placeholder="Type Title..."
                    onChange={handleChange}
                    error={touched.title && errors.title}
                  />
                  <Input
                    name="description"
                    label="Description"
                    onBlur={handleBlur}
                    placeholder="Type Description..."
                    onChange={handleChange}
                    error={touched.description && errors.description}
                  />
                  <Input
                    name="visits"
                    label="Visits"
                    onBlur={handleBlur}
                    placeholder="Type visits..."
                    onChange={handleChange}
                    error={touched.visits && errors.visits}
                  />
                </Box>
                <Box basis="45%" direction="col">
                  <Input
                    name="snapshots"
                    label="Snapshots"
                    onBlur={handleBlur}
                    placeholder="Enter snapshots..."
                    onChange={handleChange}
                    error={touched.snapshots && errors.snapshots}
                  />
                  <Input
                    name="area"
                    label="Area"
                    onBlur={handleBlur}
                    placeholder="Enter area..."
                    onChange={handleChange}
                    error={touched.area && errors.area}
                  />
                  <Input
                    name="notes"
                    label="Notes"
                    onBlur={handleBlur}
                    placeholder="Enter notes..."
                    onChange={handleChange}
                    error={touched.notes && errors.notes}
                  />
                </Box>
              </Box>
              <Box justify="end">
                <Button type="submit" disabled={!isValid}>
                  Create Vessel View
                </Button>
              </Box>
            </form>
          )}
        />
      </Box>
    );
  }
}

const mapStateToProps = (state, props) => {
  const accountList = [];
  for (let key in state.accounts.byId) {
    if (state.accounts.byId.hasOwnProperty(key)) {
      state.accounts.byId[key].value = state.accounts.byId[key].name;
      accountList.push(state.accounts.byId[key]);
    }
  }
  return {
    accountList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createUser: (values) =>
    dispatch({ type: "CREATE_VESSEL_VIEW_REQUEST", payload: { values } }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateVesselViewBox);
