import React from "react";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";

import * as utils from "../styled-utils";

const Container = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

export const Label = styled.label`
  display: inline-block;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  color: ${props =>
    props.color ? utils.colors[props.color] : utils.colors.offWhite};
`;

const Error = styled.span`
  display: flex;
  opacity: 1;
  position: relative;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  padding: 8px 16px;
  background: #4d2222;
  color: #787878;
  border: 1px solid #4d2222;
  margin-left: auto;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  transition: all 500ms ease;

  &.error-enter {
    opacity: 0.01;
  }

  &.error-enter-active {
    opacity: 1;
  }

  &.error-exit {
    opacity: 1;
  }

  &.error-exit-active {
    opacity: 0.01;
  }
`;

export const StyledInput = styled.input`
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-style: normal;

  padding: 12px 10px;
  position: relative;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: none;
  border: 1px solid ${utils.colors.lightGray};
  border-radius: 4px;
  color: ${utils.colors.offWhite};
  background-color: rgba(28, 28, 28, 0.5);
  transition: all 125ms ease-in-out;

  &:focus {
    border: 1px solid ${utils.colors.lightblue};
  }
`;

const Input = ({
  label,
  type,
  name,
  onChange,
  onBlur,
  onFocus,
  error,
  placeholder,
  defaultValue,
  value
}) => (
  <Container>
    <Label>{label}</Label>
    <StyledInput
      type={type}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      defaultValue={defaultValue}
      value={value}
      onBlur={onBlur}
      onFocus={onFocus}
      autoComplete="off"
      autoFill="off"
    />
    <CSSTransition
      in={typeof error === "string"}
      timeout={500}
      unmountOnExit
      classNames={{
        enter: "error-enter",
        enterActive: "error-enter-active",
        exit: "error-exit",
        exitActive: "error-exit-active",
      }}
    >
      <Error>{error}</Error>
    </CSSTransition>
  </Container>
);

export default Input;
