import React, { PureComponent } from "react";
import styled from "styled-components";
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import Portal from "./Portal";
import { Box } from "../index";
import { colors } from "../styled-utils";

const getStatus = status => {
  if (status === "error") {
    return colors.crimson;
  } else if (status === "success") return colors.green;
  else {
    return colors.ghost;
  }
};

const HyperBox = styled(Box)`
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3), 0 5px 8px -4px rgba(0, 0, 0, 0.47);
  border: 2px solid ${props => getStatus(props.status)};
  font-size: 12px;
  color: ${colors.offWhite};
`;

class Toast extends PureComponent {
  state = { isShowing: true };

  hide = () => {
    this.setState({ isShowing: false });
  };

  render() {
    const { children, status } = this.props;
    const { isShowing } = this.state;
    return isShowing ? (
      <Portal>
        <Box
          position="fixed"
          width="100%"
          justify="center"
          alignItems="flex-start"
          marginTop={4}
          z={999}
        >
          <HyperBox
            elevate
            background={colors.darkGray}
            shape="pill"
            paddingVertical={2}
            paddingHorizontal={2}
            maxWidth="480px"
            status={status}
          >
            <Box marginRight={1}>{children}</Box>
            <FA icon={faTimes} color={colors.ghost} onClick={this.hide} />
          </HyperBox>
        </Box>
      </Portal>
    ) : null;
  }
}

export default Toast;
