export const requestCountries = () => ({ type: "COUNTRIES_REQUEST" });
export const successCountries = ({ entities, result }) => ({
  type: "COUNTRIES_SUCCESS",
  payload: {
    entities,
    result
  }
});
export const errorCountries = error => ({
  type: "COUNTRIES_ERROR",
  payload: { ...error }
});
