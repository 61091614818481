import axios from "axios";
import { URL } from "../global/constants";

export async function updateVessel(id, data, token) {
  return axios.put(`${URL}/secured/vessels/${id}`, data, {
    headers: {
      Authorization: token,
    },
  });
}

export async function getVesselFlagsList(token) {
  return axios.get(`${URL}/secured/vesselFlags`, {
    headers: {
      Authorization: token,
    },
  });
}

export async function getVesselTypesList(token) {
  return axios.get(`${URL}/secured/vesseltypes`, {
    headers: {
      Authorization: token,
    },
  });
}
